import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { authSelectors } from '../../../../store/auth/auth.selectors';

import { PlayIcon } from '../../../../assets/images/icons';
import EmptyView from '../../../../components/EmptyView/EmptyView';
import LazyImage from '../../../../components/LazyImage/LazyImage';
import Button from '../../../../components/ui/Button/Button';
import useQueryParams from '../../../../hooks/useQueryParams';
import {
  DeviceContext,
  SCREEN_SIZES,
} from '../../../../providers/DetectDeviceProvider';
import axiosInstance from '../../../../services/interceptor';
import { features } from '../../../../whiteLabelConfigs/whitelabelConfigs';
import { CASINO_LIMIT } from '../../utils/constant';
import { CASINO_HELPER } from '../../utils/helper';
import Providers from '../Providers/Providers';
import Search from '../Search/Search';

import {
  CasinoItem,
  CasinoList,
  CasinoPlay,
  CasinoPlayIcon,
  CasinoPlayText,
  Filter,
  FilterItem,
  ShowMore,
  Wrapper,
} from './CasinoField.styled';

const CasinoField = ({ section }) => {
  const device = useContext(DeviceContext);
  const isMobile = SCREEN_SIZES[device] < SCREEN_SIZES.tablet;
  const [casinoList, setCasinoList] = useState([]);
  const [lastGame, setLastGame] = useState(null);
  const [hasMore, setHasMore] = useState(null);
  const { searchParams, setSearchParams } = useQueryParams();
  const { selectedProvider, search } = searchParams;
  const { lang } = useParams();
  const isLoggedIn = useSelector(authSelectors.selectIsLoggedIn);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onPlayClick = (uuid, section, casinoImage) => {
    CASINO_HELPER.onPlayGame({
      uuid,
      isLoggedIn,
      isMobile,
      section,
      lang,
      navigate,
      setSearchParams,
      backUrl: location.pathname + location.search,
      casinoImage,
    });
  };

  const fetchGames = moreGames => {
    axiosInstance
      .get('casino', {
        params: {
          isMobile,
          providers: selectedProvider ? [selectedProvider] : undefined,
          search,
          section,
          types: undefined,
          limit: CASINO_LIMIT,
          after: moreGames ? lastGame : undefined,
        },
      })
      .then(data => {
        setLastGame(data.last);
        setHasMore(data.hasMore);
        moreGames
          ? setCasinoList(prevState => [...prevState, ...data.entities])
          : setCasinoList(data.entities);
      });
  };

  useEffect(() => {
    fetchGames();
  }, [selectedProvider, search, section]);

  return (
    <Wrapper>
      <Filter>
        <FilterItem>
          <Providers section={section} />
        </FilterItem>
        <FilterItem>
          <Search section={section} />
        </FilterItem>
      </Filter>
      <CasinoList>
        {casinoList.length ? (
          casinoList.map(game => (
            <CasinoItem
              key={game.uuid}
              onClick={() =>
                onPlayClick(
                  game.uuid,
                  game.section,
                  game.svg || game.image || features.casinoPlaceholder,
                )
              }>
              <div className="img-container">
                <LazyImage
                  image={game.svg || game.image || features.casinoPlaceholder}
                  alt={game.title}
                  errorView={<img src={features.casinoPlaceholder} alt="" />}
                  loadView={<img src={features.casinoPlaceholder} alt="" />}
                  objectFit={'cover'}
                />
              </div>
              <CasinoPlay>
                <CasinoPlayText>Play</CasinoPlayText>
                <CasinoPlayIcon>
                  <PlayIcon />
                </CasinoPlayIcon>
              </CasinoPlay>
            </CasinoItem>
          ))
        ) : (
          <EmptyView text={'No casino games to show'} color={'white'} />
        )}
      </CasinoList>
      {hasMore ? (
        <ShowMore onClick={() => fetchGames(true)}>
          <Button
            text={t('showMore')}
            fill={'var(--sb-active)'}
            color={'var(--sb-active-contrast)'}
            big
          />
        </ShowMore>
      ) : null}
    </Wrapper>
  );
};

export default CasinoField;
