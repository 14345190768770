import React from 'react';
import { Link } from 'react-router-dom';

import * as S from '../../../../Tabs.styled';

const CreditCardDeposit = ({ deposit }) => {
  return (
    <div>
      <S.HotKeys>
        {deposit.fastValues.map(item => (
          <S.HotKey
            active
            as={Link}
            to={item.link}
            target={'_blank'}
            key={item.value}>
            {item.value} {deposit.currency}
          </S.HotKey>
        ))}
      </S.HotKeys>
    </div>
  );
};

export default CreditCardDeposit;
