import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { fetchUser } from '../../../../store/auth/auth.slice';

import { CloseIcon } from '../../../../assets/images/icons';
import axiosInstance from '../../../../services/interceptor';

import { CloseButton, Head, Inner, Wrapper } from './CasinoPlay.styled';

const CasinoPlay = () => {
  const { uuid, lang } = useParams();
  const dispatch = useDispatch();
  const [gameUrl, setGameUrl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    axiosInstance
      .post('/casino/game-init', {
        uuid,
        redirectUrl: `${window.location.origin}/${lang}/casino`,
        language: 'en',
      })
      .then(e => {
        setGameUrl(e.data);
      });

    const interval = setInterval(() => dispatch(fetchUser()), 2000);

    return () => clearInterval(interval);
  }, [uuid]);

  return (
    <Wrapper image={location.state?.casinoImage}>
      <Inner>
        <Head>
          <CloseButton
            type="button"
            onClick={() => navigate(location.state?.backUrl || `/${lang}/`)}>
            <CloseIcon />
          </CloseButton>
        </Head>
        <iframe
          src={gameUrl}
          frameBorder="0"
          allowFullScreen
          width="100%"
          height="100%"
        />
      </Inner>
    </Wrapper>
  );
};

export default CasinoPlay;
