import etheriumIcon from '../../../assets/images/payment/etherium.svg';

import bitcoinIcon from './bitcoin.svg';
// import interacIcon from './interac.svg';
import mastercardIcon from './mastercard.svg';
import usdtIcon from './usdt.svg';
// import netellerIcon from './neteller.svg';
// import paysafeIcon from './paysafe.svg';
// import skrillIcon from './skrill.svg';
import visasecureIcon from './visasecure.svg';

export const PARTNERS = [
  // {
  //   image: interacIcon,
  // },
  {
    image: bitcoinIcon,
  },
  // {
  //   image: paysafeIcon,
  // },
  // {
  //   image: netellerIcon,
  // },
  // {
  //   image: skrillIcon,
  // },
  { image: etheriumIcon },
  { image: usdtIcon },
  {
    image: mastercardIcon,
  },
  {
    image: visasecureIcon,
  },
];
