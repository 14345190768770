import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Head = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.75rem 0 0.5rem;
`;
export const HeadTitle = styled.div`
  color: #757575;
  text-transform: capitalize;
`;
export const HeadArrow = styled.div`
  display: flex;
  align-items: center;
  width: 0.875rem;

  img {
    width: 100%;
    transform: rotate(90deg);
  }
`;

export const Body = styled.div`
  position: absolute;
  bottom: -1rem;
  left: 0;
  right: 0;
  transform: translateY(100%);
  background-color: var(--sb-light-primary);
  border-radius: 20px;
  z-index: 2;
`;
export const BodyList = styled.div`
  padding: 10px 0 10px 16px;
  overflow-y: auto;
  max-height: 290px;
  width: calc(100% - 9px);
`;
export const BodyItem = styled.div`
  padding: 0.625rem 1rem;
  display: flex;
  color: #666;
  font-size: 0.75rem;
  cursor: pointer;
  text-transform: capitalize;
`;

export const Close = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;
