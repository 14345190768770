import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const SCREEN_SIZES = {
  mobile: 320,
  tablet: 768,
  laptop: 1400,
  desktop: 1600,
};

function detectDevice(innerWidth) {
  if (innerWidth < SCREEN_SIZES.tablet) {
    return 'mobile';
  } else if (innerWidth < SCREEN_SIZES['laptop']) {
    return 'tablet';
  } else if (innerWidth < SCREEN_SIZES['desktop']) {
    return 'laptop';
  } else {
    return 'desktop';
  }
}

export const DeviceContext = React.createContext('');

const DetectDeviceProvider = ({ children }) => {
  const [device, setDevice] = useState(detectDevice(window.innerWidth));

  useEffect(() => {
    function resizeHandler(e) {
      setDevice(detectDevice(e.target.innerWidth));
    }

    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return (
    <DeviceContext.Provider value={device}>{children}</DeviceContext.Provider>
  );
};

export default DetectDeviceProvider;

DetectDeviceProvider.propTypes = {
  children: PropTypes.node,
};
