import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authSlice from './auth/auth.slice';
import globalSlice from './global/global.slice';

const persistConfigs = {
  auth: {
    key: 'auth',
    storage: storage,
    whitelist: ['accessToken', 'refreshToken'],
  },
  betSlip: {
    key: 'betSlip',
    storage: storage,
    whitelist: ['betSlip', 'step'],
  },
};

const store = configureStore({
  reducer: {
    global: globalSlice,
    auth: persistReducer(persistConfigs.auth, authSlice),
  },
});

export let persistor = persistStore(store);

export default store;
