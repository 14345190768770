import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { SCREEN_SIZES } from '../../../../providers/DetectDeviceProvider';

export const Wrapper = styled.header`
  display: flex;
  height: 4.25rem;
  background-color: var(--sb-background-primary);
  padding: 0 1rem;
  position: sticky;
  z-index: 2;
  top: 0;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    padding: 0 2.5rem;
  }

  @media screen and (min-width: ${SCREEN_SIZES.laptop}px) {
    justify-content: space-between;
    padding: 0 7.5rem;
  }
`;

export const LeftSide = styled.div`
  display: flex;
`;

export const Nav = styled.div`
  display: flex;
`;
export const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: var(--sb-light-primary);
  margin-left: 1.5rem;
  cursor: pointer;
  text-decoration: none;

  @media screen and (min-width: ${SCREEN_SIZES.laptop}px) {
    &:first-child {
      margin-left: 0;
    }
  }

  &.active {
    color: var(--sb-active);

    svg {
      fill: var(--sb-active);
    }
  }

  &:not(.active):hover {
    color: var(--sb-active-hover);

    svg {
      fill: var(--sb-active-hover);
    }
  }
`;
export const NavIcon = styled.div`
  width: 1.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--sb-light-primary);
  }

  img {
    width: 100%;
  }
`;
export const NavText = styled.div`
  margin-left: 0.5rem;
`;

export const MenuButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  margin-right: 1.375rem;
  cursor: pointer;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const Logo = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  width: 9.625rem;

  img {
    width: 100%;
  }
`;

export const RightSide = styled.div`
  display: flex;
  margin-left: auto;

  @media screen and (min-width: ${SCREEN_SIZES.laptop}px) {
    margin-left: 0;
  }
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-left: 0.625rem;
  }
`;
