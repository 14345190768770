import { API } from '../../api';
import axiosInstance from '../../services/interceptor';
import { features } from '../../whiteLabelConfigs/whitelabelConfigs';

async function signIn(params) {
  return axiosInstance.post(API.auth.signIn, params);
}

async function getMe() {
  return axiosInstance.get(API.auth.me);
}

async function signUp(params) {
  return axiosInstance.post(`${features.auth.requestVersion}/sign-up`, params);
}

export const authApi = {
  signIn,
  getMe,
  signUp,
};
