import axiosInstance from '../../../services/interceptor';

const onPlayGame = ({
  isLoggedIn,
  isMobile,
  lang,
  section,
  uuid,
  navigate,
  setSearchParams,
  backUrl,
  casinoImage,
}) => {
  if (isLoggedIn) {
    if (isMobile) {
      axiosInstance
        .post('/casino/game-init', {
          uuid: uuid,
          redirectUrl: `${location.origin}/${lang}/${section}`,
          language: lang,
          isMobile: isMobile ? 1 : 0,
        })
        .then(e => {
          if (e.data) {
            return { data: e.data };
          } else if (e.iframe) {
            const div = document.createElement('div');
            div.innerHTML = e.iframe;
            document.location.href = div.querySelector('iframe').src;
          }
        })
        .then(e => (document.location.href = e.data));
    } else {
      navigate(`/${lang}/game-init/${uuid}`, {
        state: { backUrl, casinoImage },
      });
    }
  } else {
    setSearchParams({ popup: 'sign-in' });
  }
};

export const CASINO_HELPER = { onPlayGame };
