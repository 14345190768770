import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import casinoBanner from '../../../assets/images/banners/Casino.png';
import sportBanner from '../../../assets/images/banners/sport.png';

import 'swiper/css';

const Banners = () => {
  return (
    <Swiper
      slidesPerView={1}
      onSlideChange={() => console.log('slide change')}
      onSwiper={swiper => console.log(swiper)}>
      <SwiperSlide>
        <img src={sportBanner} alt="" width="100%" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={casinoBanner} alt="" width="100%" />
      </SwiperSlide>
    </Swiper>
  );
};

export default Banners;
