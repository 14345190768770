import { features } from '../whiteLabelConfigs/whitelabelConfigs';

const authVersion = features.auth.requestVersion;

export const AUTH_API = {
  forgotPassword: `${authVersion}/forgot-password`,
  checkPhone: `${authVersion}/check-phone`,
  checkEmail: `${authVersion}/check-email`,
  checkUsername: `${authVersion}/check-username`,
  changePassword: `${authVersion}/change-password`,
  resetPassword: `${authVersion}/reset-password`,
  signIn: `${authVersion}/sign-in`,
  signUp: `${authVersion}/sign-up`,
  me: `me`,
};
