import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { authSelectors } from '../store/auth/auth.selectors';

import EmailVerification from '../components/Auth/EmailVerification';
import ResetPassword from '../components/Auth/ResetPassword';

import { getDefaultLanguage } from './I18nextProvider';

const CheckAuthRoutes = ({ children }) => {
  const [init, setInit] = useState(false);
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const isLoggedIn = useSelector(authSelectors.selectIsLoggedIn);

  useEffect(() => {
    if (
      isLoggedIn &&
      (location.pathname === '/verify-email' ||
        location.pathname === '/reset-password')
    ) {
      setInit(true);
      navigate(`/${getDefaultLanguage()}/home`, { replace: true });

      return;
    }

    if (
      location.pathname === '/verify-email' ||
      location.pathname === '/reset-password'
    ) {
      setToken({
        type: location.pathname.replace('/', ''),
        value: location.search.replace('?token=', ''),
      });
      navigate(`/${getDefaultLanguage()}/home`, { replace: true });
    }
    setInit(true);
  }, []);

  return (
    init && (
      <>
        {token?.type === 'verify-email' && (
          <EmailVerification
            token={token.value}
            onClose={() => setToken(null)}
          />
        )}
        {token?.type === 'reset-password' && (
          <ResetPassword onClose={() => setToken(false)} token={token.value} />
        )}
        {children}
      </>
    )
  );
};

export default CheckAuthRoutes;
