import styled from 'styled-components';

import threeStepsImage from '../../assets/images/threeStep/three-steps.png';
import { SCREEN_SIZES } from '../../providers/DetectDeviceProvider';

export const Wrapper = styled.div`
  padding: 1rem 0 2.5rem;

  @media screen and (min-width: ${SCREEN_SIZES.laptop}px) {
    padding: 4rem 0;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
`;

export const WrapperInner = styled.div`
  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    width: 90%;
    margin: 0 auto;
  }
`;

export const BonusField = styled.div`
  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
  }
`;

export const Bonus = styled.div`
  color: var(--sb-light-secondary);
  text-align: center;
  padding: 0 1rem;
  margin-top: 2.5rem;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    width: 40%;
    text-align: left;
    padding: 0;
  }
`;
export const BonusSubtitle = styled.div`
  margin-top: 1.625rem;
  font-size: 0.875rem;
  line-height: 1.6;
`;
export const BonusTitle = styled.div`
  font-size: 30px;
`;
export const BonusButton = styled.div`
  width: 9.4375rem;
  margin: 1.625rem auto 0;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    margin: 1.625rem 0 0;
  }
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  color: var(--sb-light-primary);
  background-image: url(${threeStepsImage});
  background-size: 100% 100%;
  padding: 3rem 4rem;

  @media screen and (min-width: ${SCREEN_SIZES.laptop}px) {
    padding: 4rem 6rem;
  }
`;

export const BannerCount = styled.div`
  font-size: 120px;
  margin-right: 0.5rem;
`;

export const BannerInfo = styled.div`
  width: 7rem;
`;

export const BannerTitle = styled.div`
  font-size: 28px;
`;

export const BannerText = styled.div`
  font-size: 20px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    flex-direction: row;
    justify-content: space-around;
  }

  @media screen and (min-width: ${SCREEN_SIZES.laptop}px) {
    justify-content: flex-start;
  }
`;
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--sb-light-secondary);
  &:not(:first-child) {
    margin-top: 1.25rem;
  }

  @media screen and (min-width: ${SCREEN_SIZES.laptop}px) {
    align-items: flex-start;
    justify-content: space-between;
    &:not(:last-child) {
      margin-right: 4.6875rem;
    }
    &:not(:first-child) {
      margin-top: 0;
    }
  }
`;
export const Top = styled.div`
  display: flex;
  align-items: center;
`;
export const Image = styled.div`
  width: 68px;

  img {
    width: 100%;
  }
`;
export const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--sb-light-primary);
  font-size: 1.5rem;
  margin-left: 22px;
  color: black;
  border-radius: 50%;
`;
export const Title = styled.div`
  font-size: 1.25rem;
  margin-top: 1rem;

  @media screen and (min-width: ${SCREEN_SIZES.laptop}px) {
    font-size: 1.875rem;
  }
`;
export const Text = styled.div`
  font-size: 12px;
  margin-top: 0.5rem;

  @media screen and (min-width: ${SCREEN_SIZES.laptop}px) {
    font-size: 14px;
  }
`;

export const JackpotField = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto 0;
`;
