const whiteLabelFlag = process.env.REACT_APP_LABEL_FLAG;

const {
  default: features,
} = require(`../whiteLabelConfigs/${whiteLabelFlag}.config`);

const currencySymbols = {
  TND: 'TD',
};

const defaultCurrency = 'TND';

export { currencySymbols, defaultCurrency, features };
