import React from 'react';

import classes from './Loading.module.css';

const SmallLoader = ({ color }) => {
  return (
    <div className={classes.ldsSpinner} style={{ color }}>
      {Array.from(Array(4), (_, i) => (
        <div key={i} style={color ? { background: `${color}` } : {}} />
      ))}
    </div>
  );
};

export default SmallLoader;
