import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchConfigs } from '../store/global/global.slice';

import SuspenseComponent from '../components/SuspenseComponent/SuspenseComponent';
import { features } from '../whiteLabelConfigs/whitelabelConfigs';

Number.prototype.toTruncFixed = function () {
  return (parseInt(this * 100) / 100).toFixed(2);
};

function commasInteger(num) {
  return Math.floor(num)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

Number.prototype.toTruncFixed = function ({ isCommasInteger = false } = {}) {
  if (isCommasInteger && features.isCommasInteger) {
    return commasInteger(this);
  }

  return (parseInt(this * 100) / 100).toFixed(2);
};

const InitialConfigProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchConfigs())
      .unwrap()
      .finally(() => setLoading(false));
  }, []);

  return loading ? <SuspenseComponent /> : <>{children}</>;
};

export default InitialConfigProvider;

InitialConfigProvider.propTypes = {
  children: PropTypes.node,
};
