import casinoPlaceholder from '../assets/images/casino/placeholder.webp';
import withdrawImage from '../assets/images/icons/withdraw.svg';
import logoImage from '../assets/images/logo/win-pot.svg';
import creditCardImage from '../assets/images/payment/credit-card.svg';
import cryptoImage from '../assets/images/payment/crypto.svg';

const consoleMessage = `
######################################################
#      _                       _                   __                             
#     (_)   ____ _    ____    (_)   _  __  ___    / /     _____  ____    ____ ___ 
#    / /   / __ \`/   / __ \\  / /   | |/_/ / _ \\  / /     / ___/ / __ \\  / __ \`__ \\
#   / /   / /_/ /   / /_/ / / /   _>  <  /  __/ / /   _ / /__  / /_/ / / / / / / /
#  /_/    \\__, /   / .___/ /_/   /_/|_|  \\___/ /_/   (_)\\___/  \\____/ /_/ /_/ /_/ 
#        /____/   /_/                                                             
#                                           
# URL: https://igpixel.com
#
######################################################
`;

console.log(consoleMessage);

export default {
  title: 'WinPot377',
  theme: 'main',
  logo: logoImage,
  casinoPlaceholder: casinoPlaceholder,
  auth: {
    requestVersion: '',
    withRegistration: false,
    currency: {
      default: 'EUR',
      list: ['EUR'],
    },
  },
  deposit: {
    list: [
      {
        name: 'newCrypto',
        minAmount: 50,
        maxAmount: 1000,
        currency: 'EUR',
        image: cryptoImage,
        fastValues: [50, 100, 150, 200],
      },
      {
        name: 'creditCard',
        minAmount: 50,
        maxAmount: 500,
        currency: 'EUR',
        image: creditCardImage,
        fastValues: [
          {
            value: 50,
            link: 'https://further-knowledge.com/checkout/?add-to-cart=2326&quantity=1&custom_redirect_to=1',
          },
          {
            value: 100,
            link: 'https://further-knowledge.com/checkout/?add-to-cart=1224&quantity=1&custom_redirect_to=1',
          },
          {
            value: 300,
            link: 'https://further-knowledge.com/checkout/?add-to-cart=1244&quantity=1&custom_redirect_to=1',
          },
          {
            value: 500,
            link: 'https://further-knowledge.com/checkout/?add-to-cart=1245&quantity=1&custom_redirect_to=1',
          },
        ],
      },
    ],
  },
  withdraw: {
    list: [
      {
        name: 'crypto',
        minAmount: 50,
        maxAmount: 1000,
        currency: 'EUR',
        image: cryptoImage,
      },
      {
        name: 'manual',
        minAmount: 0.5,
        maxAmount: 1000,
        currency: 'EUR',
        image: withdrawImage,
      },
    ],
  },
  poweredBy: 'igpixel.com',
};
