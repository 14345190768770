import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { authSelectors } from '../store/auth/auth.selectors';

const AuthGuard = ({ children }) => {
  const [previousRoute, setPreviousRoute] = useState(null);
  const { pathname } = useLocation();
  const { lang } = useParams();
  const isAuthenticated = useSelector(authSelectors.selectIsLoggedIn);

  useEffect(() => {
    if (previousRoute !== null) {
      setPreviousRoute(pathname);
    }
  }, [pathname, previousRoute]);

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return <Navigate to={`/${lang}`} state={{ redirectUrl: previousRoute }} />;
};

export default AuthGuard;
