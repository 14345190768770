import React, { useContext, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowImage from '../../../../assets/images/icons/arrow-right.svg';
import useQueryParams from '../../../../hooks/useQueryParams';
import {
  DeviceContext,
  SCREEN_SIZES,
} from '../../../../providers/DetectDeviceProvider';
import axiosInstance from '../../../../services/interceptor';
import { CASINO_LIMIT } from '../../utils/constant';

import {
  Body,
  BodyItem,
  BodyList,
  Close,
  Head,
  HeadArrow,
  HeadTitle,
  Wrapper,
} from './Providers.styled';

const ROUTE_NAMES = {
  casino: 'slot',
  'live-casino': 'live',
  'virtual-sport': 'virtual',
};

const Providers = ({ section }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [types, setTypes] = useState(false);
  const { searchParams, deleteParam } = useQueryParams();
  const { selectedType, selectedProvider } = searchParams;
  const device = useContext(DeviceContext);
  const isMobile = SCREEN_SIZES[device] < SCREEN_SIZES.tablet;
  const navigate = useNavigate();
  const { lang } = useParams();

  const fetchProviders = () => {
    axiosInstance
      .get('casino/providers', {
        params: {
          isMobile,
          search: '',
          section,
          type: selectedType ? selectedType : null,
          limit: CASINO_LIMIT,
        },
      })
      .then(data => {
        setTypes(data);
      });
  };

  useLayoutEffect(() => {
    fetchProviders();
  }, [selectedType, section]);

  if (!types?.length) {
    return null;
  }

  return (
    <Wrapper>
      <Head onClick={() => setIsOpened(true)}>
        <HeadTitle>{selectedProvider || 'All'}</HeadTitle>
        <HeadArrow>
          <img src={ArrowImage} alt="" />
        </HeadArrow>
      </Head>
      {isOpened && (
        <>
          <Body>
            <BodyList>
              <BodyItem
                onClick={() => {
                  deleteParam('selectedProvider');
                  setIsOpened(false);
                }}>
                All
              </BodyItem>
              {types.map(item => (
                <BodyItem
                  key={item}
                  onClick={() => {
                    navigate(
                      `/${lang}/casino/${ROUTE_NAMES[section]}?selectedProvider=${item.name}`,
                      { replace: true },
                    );
                    setIsOpened(false);
                  }}>
                  {item.name}
                </BodyItem>
              ))}
            </BodyList>
          </Body>
          <Close onClick={() => setIsOpened(false)} />
        </>
      )}
    </Wrapper>
  );
};

export default Providers;
