import { useMemo } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import AuthGuard from './guards/AuthGuard';
import Account from './pages/Account/Account';
import History from './pages/Account/components/History/History';
import MyAccount from './pages/Account/components/MyAccount/MyAccount';
import Casino from './pages/Casino/Casino';
import CasinoField from './pages/Casino/components/CasinoField/CasinoField';
import CasinoPlay from './pages/Casino/components/CasinoPlay/CasinoPlay';
import Error from './pages/Error/Error';
import Home from './pages/Home/Home';
import Layout from './pages/Layout/Layout';
import AlternarSportsbook from './AlternarSportsbook';

const getRouter = lang => [
  { path: '', element: <Navigate to={lang} replace /> },
  {
    path: ':lang',
    element: <Layout />,
    errorElement: <Error />,
    children: [
      { path: '', element: <Navigate to="home" replace /> },
      { path: 'home', element: <Home /> },
      {
        path: 'game-init/:uuid',
        element: (
          <AuthGuard>
            <CasinoPlay />
          </AuthGuard>
        ),
      },
      {
        path: 'casino',
        element: <Casino section={'casino'} />,
        children: [
          { path: '', element: <Navigate to="slot" replace /> },
          { path: 'slot', element: <CasinoField section={'casino'} /> },
          { path: 'live', element: <CasinoField section={'live-casino'} /> },
          {
            path: 'virtual',
            element: <CasinoField section={'virtual-sport'} />,
          },
        ],
      },
      { path: 'sportsbook/*', element: <AlternarSportsbook /> },
      {
        path: 'account',
        element: (
          <AuthGuard>
            <Account />
          </AuthGuard>
        ),
        children: [
          { path: '', element: <MyAccount /> },
          { path: 'history', element: <History /> },
        ],
      },
    ],
  },
];

const Router = () => {
  const router = useMemo(() => {
    // const lang = getDefaultLanguage();
    return getRouter('en');
  }, []);

  return <>{useRoutes(router)}</>;
};

export default Router;
