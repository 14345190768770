import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem 0;
  color: ${({ color }) => color && color};
  flex-grow: 1;

  svg {
    fill: ${({ color }) => color && color};
  }
`;

export const Icon = styled.div`
  margin-bottom: 1rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`;

export const Text = styled.div`
  font-size: 1.125rem;
  font-weight: 700;
`;
