import { css } from 'styled-components';

import { SCREEN_SIZES } from '../providers/DetectDeviceProvider';

export const globalStyles = css`
  * {
    padding: 0;
    margin: 0;
    border: 0;
  }

  html {
    font-size: 16px;

    &.dontScroll {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
        position: static;
        overflow: hidden;
      }
    }
  }

  body {
    font-family: 'Proxima Nova Th', sans-serif;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .hidden {
    display: none;
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .img-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
