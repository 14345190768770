import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';

import { Inner, Tab, Tabs, Title, Wrapper } from './Account.styled';

const TABS = [
  { name: 'my account', path: '' },
  // { name: 'history', path: 'history' },
];
const Account = () => {
  const { t } = useTranslation();
  const { lang } = useParams();
  return (
    <Wrapper>
      <Title>Account</Title>
      <Tabs>
        {TABS.map(tab => (
          <Tab to={`/${lang}/account/${tab.path}`} key={tab.name}>
            {t(tab.name)}
          </Tab>
        ))}
      </Tabs>
      <Inner>
        <Outlet />
      </Inner>
    </Wrapper>
  );
};

export default Account;
