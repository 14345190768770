import { css } from 'styled-components';

export const cssVariables = css`
  html[data-theme='main'] {
    --sb-active: rgb(243, 222, 91);
    --sb-active-hover: rgba(243, 222, 91, 0.7);
    --sb-active-contrast: rgb(20, 20, 20);
    --sb-background-primary: rgb(22, 28, 61);
    --sb-background-footer: rgb(22, 23, 34);
    --sb-light-primary: rgb(255, 255, 255);
    --sb-light-secondary: rgb(224, 224, 224);
    //--sb-active: rgb(242 91 243);
    //--sb-active-hover: rgba(243, 222, 91, 0.7);
    //--sb-active-contrast: rgb(255 255 255);
    //--sb-background-primary: rgb(53 11 129);
    //--sb-background-footer: rgb(18 23 74);
    //--sb-light-primary: rgb(255 237 237);
    //--sb-light-secondary: rgb(165 165 165);
  }
`;
