import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { authSelectors } from '../../../../store/auth/auth.selectors';

import { CasinoIcon, SportIcon } from '../../../../assets/images/icons';
import menuIcon from '../../../../assets/images/icons/burger-menu.svg';
import loginIcon from '../../../../assets/images/icons/login.svg';
import paymentImage from '../../../../assets/images/icons/payment.svg';
import registerIcon from '../../../../assets/images/icons/register.svg';
import userImage from '../../../../assets/images/icons/user.svg';
import LanguageSelection from '../../../../components/LanguageSelection/LanguageSelection';
import Button from '../../../../components/ui/Button/Button';
import useQueryParams from '../../../../hooks/useQueryParams';
import {
  DeviceContext,
  SCREEN_SIZES,
} from '../../../../providers/DetectDeviceProvider';
import { features } from '../../../../whiteLabelConfigs/whitelabelConfigs';

import * as S from './Header.styled';

const Header = ({ toggleMenu }) => {
  const { lang } = useParams();
  const device = useContext(DeviceContext);
  const isMobile = SCREEN_SIZES[device] < SCREEN_SIZES.tablet;
  const isTablet = SCREEN_SIZES[device] < SCREEN_SIZES.laptop;
  const { setSearchParams } = useQueryParams();
  const isLoggedIn = useSelector(authSelectors.selectIsLoggedIn);
  const user = useSelector(authSelectors.selectUser);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.LeftSide>
        {!isMobile && (
          <S.MenuButton onClick={() => toggleMenu(true)}>
            <img src={menuIcon} alt="" />
          </S.MenuButton>
        )}
        <S.Logo to={`/${lang}`}>
          <img src={features.logo} alt="" />
        </S.Logo>
      </S.LeftSide>
      {!isMobile && (
        <S.Nav>
          <S.NavItem to={`/${lang}/casino`}>
            <S.NavIcon>
              <CasinoIcon />
            </S.NavIcon>
            {!isTablet && <S.NavText>{t('casino.name')}</S.NavText>}
          </S.NavItem>
          <S.NavItem to={`/${lang}/sportsbook`}>
            <S.NavIcon>
              <SportIcon />
            </S.NavIcon>
            {!isTablet && <S.NavText>{t('sport')}</S.NavText>}
          </S.NavItem>
        </S.Nav>
      )}
      <S.RightSide>
        {!isTablet && <LanguageSelection />}
        {isLoggedIn ? (
          <>
            {!isMobile ? (
              <S.Button>
                <Button
                  image={paymentImage}
                  fill={'var(--sb-active)'}
                  color={'var(--sb-active-contrast)'}
                  text={'Payment'}
                  onClick={() => setSearchParams({ popup: 'payment' })}
                />
              </S.Button>
            ) : null}
            <S.Button>
              <Button
                image={userImage}
                color={'var(--sb-light-primary)'}
                text={
                  <div>
                    <div>Balance</div>
                    <div style={{ textAlign: 'left' }}>
                      € {user.balance.toTruncFixed()}
                    </div>
                  </div>
                }
                onClick={() => navigate(`/${lang}/account/`)}
              />
            </S.Button>
          </>
        ) : (
          <>
            <S.Button>
              <Button
                image={loginIcon}
                stroke={'var(--sb-light-primary)'}
                text={isMobile ? '' : t('login')}
                onClick={() => setSearchParams({ popup: 'sign-in' })}
              />
            </S.Button>
            {features.auth.withRegistration && (
              <S.Button>
                <Button
                  image={registerIcon}
                  fill={'var(--sb-active)'}
                  color={'var(--sb-active-contrast)'}
                  text={isMobile ? '' : t('register')}
                  onClick={() => setSearchParams({ popup: 'sign-up' })}
                />
              </S.Button>
            )}
          </>
        )}
      </S.RightSide>
    </S.Wrapper>
  );
};

export default Header;
