import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { GlobalStyledProvider } from '../styles';
import { features } from '../whiteLabelConfigs/whitelabelConfigs';

const MyThemeProvider = ({ children }) => {
  const [dir, setDir] = useState(i18n.dir());

  useEffect(() => {
    i18n.on('languageChanged', () => {
      setDir(i18n.dir());

      document.documentElement.setAttribute('dir', i18n.dir());
    });

    document.documentElement.setAttribute('data-theme', features.theme);
  }, []);

  return (
    <>
      <GlobalStyledProvider />
      <ThemeProvider theme={{ dir }}>{children}</ThemeProvider>
    </>
  );
};

export default MyThemeProvider;

MyThemeProvider.propTypes = {
  children: PropTypes.any,
};
