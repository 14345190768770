import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';
import capitalize from 'lodash/capitalize';

import { fetchUser } from '../../../../../../../store/auth/auth.slice.js';

import { CopyIcon } from '../../../../../../../assets/images/icons';
import axiosInstance from '../../../../../../../services/interceptor.js';
import MessagePopup from '../../../../../../ui/Popups/MessagePopup';
import PaymentSelect from '../../../../../PaymentSelect/PaymentSelect';

import * as S from './NewCryptoDeposit.styles';

const NewCrypto = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [popupMessage, setPopupMessage] = useState(null);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const getDepositAddress = useCallback(() => {
    setLoading(true);
    axiosInstance
      .get('payment/crypto-payment/deposit-address')
      .then(setData)
      .catch(error => {
        setPopupMessage({
          type: 'error',
          message: capitalize(error),
        });
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    let timeOut;
    let isUnmount = false;

    getDepositAddress();

    let getUser = () => {
      clearTimeout(timeOut);

      dispatch(fetchUser())
        .unwrap()
        .then(() => {
          if (!isUnmount) {
            timeOut = setTimeout(() => {
              getUser();
            }, 30000);
          }
        });
    };

    getUser();

    return () => {
      clearTimeout(timeOut);
      isUnmount = true;
    };
  }, []);

  const { address } = data || {};

  const copyToClipboard = () => {
    navigator.clipboard.writeText(address);
    setPopupMessage({ type: 'success', message: t('copied') });
  };

  return loading ? (
    <div>loading</div>
  ) : (
    <S.Wrapper>
      <PaymentSelect
        options={['USDT (TRC20)']}
        value={'USDT (TRC20)'}
        label="Deposit Network"
        onChange={() => {}}
      />

      <S.Section>
        <S.QRWrapper>
          <QRCode
            style={{ height: 'auto', width: '100%' }}
            value={address}
            viewBox={`0 0 256 256`}
          />
        </S.QRWrapper>

        <S.AddressBlock>
          <S.Label>{t('address')}</S.Label>

          <S.Wallet>
            <S.Title>{address}</S.Title>
            <S.CopyButton type="button" onClick={() => copyToClipboard()}>
              <CopyIcon />
            </S.CopyButton>
          </S.Wallet>
        </S.AddressBlock>
      </S.Section>

      <S.Label color="var(--bw-text-color-base)">Quick Tips</S.Label>
      <S.Section style={{ padding: '1rem' }}>
        <S.Title>
          Please always check your deposit wallet address on our site before
          making your transactions.
        </S.Title>
      </S.Section>
      {popupMessage && (
        <MessagePopup
          type={popupMessage.type}
          message={popupMessage.message}
          onClose={() => setPopupMessage(false)}
        />
      )}
    </S.Wrapper>
  );
};

export default NewCrypto;
