import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  flex-grow: 1;
`;

export const Filters = styled.div`
  display: flex;
  gap: 2rem;
  padding: 0 2.5rem;
`;

export const SelectWrapper = styled.div`
  width: 50%;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  flex-shrink: 0;
  margin-top: 2rem;
  height: calc(100% - 140px);
`;

export const renderCols = () => css`
  div {
    flex-shrink: 0;
    width: 20%;
    text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;

  ${renderCols}
`;

export const List = styled.ul`
  height: calc(100% - 26px);
  overflow-y: auto;
`;

export const Item = styled.li`
  display: flex;
  padding: 8px 0;
  font-size: 12px;
  justify-content: space-between;
  background-color: #c8c8c8;

  ${renderCols};
`;

export const CancelButton = styled.button`
  background-color: #bf0303;
  color: white;
  padding: 0.3rem 0.5rem;
  border-radius: 3px;
  cursor: pointer;
`;
