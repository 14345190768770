import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getConfigs, getMainBanner } from './global.API';

const initialState = {
  supportedLanguages: [],
  mainBanner: [],
};

export const fetchConfigs = createAsyncThunk(
  'global/fetchConfigs',
  async params => {
    return await getConfigs(params);
  },
);

export const fetchMainBanner = createAsyncThunk(
  'global/fetchMainBanner',
  async params => {
    return await getMainBanner(params);
  },
);

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchConfigs.fulfilled, (state, action) => {
      state.supportedLanguages = action.payload.languages;
    });
    builder.addCase(fetchMainBanner.fulfilled, (state, action) => {
      state.mainBanner = action.payload.desktop;
    });
  },
});

const { reducer } = globalSlice;

export default reducer;
