import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.span`
  display: block;
  margin-bottom: 8px;
`;

export const SelectWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    width: 1rem;
    height: 1rem;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const Select = styled.select`
  width: 100%;
  height: 48px;
  padding: 0 2.5rem 0 1rem;
  border-radius: 5px;
  border: 3px solid rgb(155, 140, 215);
  background-color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 1rem;
  color: var(--sb-active-contrast);
  appearance: none;
`;
