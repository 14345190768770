import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ArrowDownIcon } from '../../../../../../../assets/images/icons';
import axiosInstance from '../../../../../../../services/interceptor';
import Button from '../../../../../../ui/Button/Button';
import PaymentSelect from '../../../../../PaymentSelect/PaymentSelect';
import * as S from '../../../../Tabs.styled';

const options = {
  USDT: ['TRON', 'ETH'],
  TRX: ['TRON'],
  BTC: ['BTC'],
  ETH: ['ETH'],
  LTC: ['LTC'],
  DASH: ['DASH'],
};

const currencies = Object.keys(options);

const BackButton = styled.button`
  color: rgb(110, 109, 122);
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    margin-right: 0.5rem;

    svg {
      width: 12px;
      height: 12px;

      transform: rotate(90deg);
    }
  }
`;

const CreateWallet = ({ onFinished, onError, onBack }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const { handleSubmit, control, setValue, watch } = useForm({
    defaultValues: {
      currency: currencies[0],
      network: options[currencies[0]][0],
    },
  });

  const onSubmit = data => {
    if (loading) {
      return;
    }

    setLoading(true);

    axiosInstance
      .post('crypto/wallet', data)
      .then(() => {
        onFinished();
      })
      .catch(error => onError(error.message))
      .finally(() => setLoading(false));
  };

  const [currency] = watch(['currency']);
  const networks = options[currency];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BackButton onClick={onBack}>
        <span>
          <ArrowDownIcon />
        </span>
        Back
      </BackButton>
      <S.InputWrapper>
        <PaymentSelect
          onChange={e => setValue('network', options[e.target.value][0])}
          options={currencies}
          formData={{ control, name: 'currency' }}
          label={t('currency')}
        />
      </S.InputWrapper>

      <S.InputWrapper>
        <PaymentSelect
          options={networks}
          formData={{ control, name: 'network' }}
          label={t('network')}
        />
      </S.InputWrapper>

      <S.Button style={{ width: '100%' }}>
        <Button
          text={'Create'}
          fill={'#3db860'}
          color={'var(--sb-light-primary)'}
          big
          type="submit"
          loading={loading}
        />
      </S.Button>
    </form>
  );
};

export default CreateWallet;
