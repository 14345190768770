import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  CasinoIcon,
  LiveCasinoIcon,
  VirtualGamesIcon,
} from '../../../../assets/images/icons';

import { Icon, Item, Name, Wrapper } from './Types.styled';

const Types = () => {
  const { lang } = useParams();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Item to={`/${lang}/casino/slot`}>
        <Icon>
          <CasinoIcon />
        </Icon>
        <Name>{t('casino.name')}</Name>
      </Item>
      <Item to={`/${lang}/casino/live`}>
        <Icon>
          <LiveCasinoIcon />
        </Icon>
        <Name>{t('liveCasino')}</Name>
      </Item>
      <Item to={`/${lang}/casino/virtual`}>
        <Icon>
          <VirtualGamesIcon />
        </Icon>
        <Name>{t('virtualGames')}</Name>
      </Item>
    </Wrapper>
  );
};

export default Types;
