import { createSelector } from '@reduxjs/toolkit';

const selectGlobal = state => state.global;

export const getSupportedLanguages = createSelector(
  [selectGlobal],
  global => global.supportedLanguages,
);

export const getMainBanners = createSelector(
  [selectGlobal],
  global => global.mainBanner,
);
