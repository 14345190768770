import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { authApi } from './auth.API';

const initialState = {
  isLoggedIn: false,
  loading: false,
  user: null,
  error: null,
  accessToken: null,
  refreshToken: null,
};

export const fetchSignIn = createAsyncThunk('auth/fetchSignIn', async params =>
  authApi.signIn(params),
);

export const fetchUser = createAsyncThunk('auth/fetchUser', async () =>
  authApi.getMe(),
);

export const signUp = createAsyncThunk('auth/signUp', params =>
  authApi.signUp(params),
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetErrors(state) {
      state.error = null;
    },
    init(state, action) {
      const { user, isLoggedIn } = action.payload;
      state.isLoggedIn = isLoggedIn;
      state.user = user;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
    },
    setToken(state, action) {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    updateBalance(state, action) {
      state.user.balance = action.payload.balance;
      state.user.displayBalance = action.payload.displayBalance;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSignIn.pending, state => {
      state.error = null;
    });
    builder.addCase(fetchSignIn.rejected, state => {
      state.error = true;
    });
    builder.addCase(fetchSignIn.fulfilled, (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(fetchUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchUser.rejected, state => {
      state.loading = false;
      state.isLoggedIn = false;
    });
    builder.addCase(signUp.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(signUp.pending, state => {
      state.loading = true;
    });
    builder.addCase(signUp.rejected, state => {
      state.loading = false;
    });
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
