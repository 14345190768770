import React from 'react';
import dayjs from 'dayjs';
import styled, { css } from 'styled-components';

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  flex-shrink: 0;
`;

const renderCols = () => css`
  div {
    flex-shrink: 0;

    :nth-child(1n) {
      width: 350px;
    }

    :nth-child(2n) {
      width: 150px;
    }

    :nth-child(3n) {
      width: 150px;
    }

    :nth-child(4n) {
      width: 150px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 8px;

  ${renderCols}
`;

export const List = styled.ul``;

export const Item = styled.li`
  display: flex;
  margin-bottom: 8px;
  font-size: 12px;

  ${renderCols};
`;

const CryptoDepositStyles = ({ wallets }) => {
  return (
    <Table>
      <Row>
        <div>Wallet</div>
        <div>Currency</div>
        <div>Network</div>
        <div>Created At</div>
      </Row>
      <List>
        {wallets.map(wallet => (
          <Item key={wallet.address}>
            <div>{wallet.address}</div>
            <div>{wallet.currency}</div>
            <div>{wallet.network}</div>

            <div>{dayjs.unix(wallet.createdAt).format('DD/MM HH:mm:ss')}</div>
          </Item>
        ))}
      </List>
    </Table>
  );
};

export default CryptoDepositStyles;
