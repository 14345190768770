import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 5rem;

  svg {
    fill: rgb(110, 109, 122);
    color: rgb(110, 109, 122);
  }

  p {
    color: rgb(110, 109, 122);
    margin: 0;
    margin-bottom: 0.5rem;
    text-align: center;
  }
`;

const EmptyWallet = ({ title, text, children, style }) => {
  return (
    <Container style={style}>
      <p>{title}</p>
      <p>{text}</p>
      {children}
    </Container>
  );
};

export default EmptyWallet;
