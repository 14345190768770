import styled from 'styled-components';

import { SCREEN_SIZES } from '../../providers/DetectDeviceProvider';

export const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  overflow: hidden;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--sb-light-primary);
  padding-top: 2.5rem;
  position: relative;
  overflow-y: auto;
  height: 100%;
  width: 100%;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    width: 90%;
    max-width: 800px;
    height: 650px;
    border-radius: 1rem;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 18px;
  right: 18px;
  font-size: 20px;
  font-weight: bold;
  background-color: transparent;
  border: none;
  cursor: pointer;

  svg {
    width: 36px;
    height: 36px;
    fill: #c4c4c4;
  }
`;

export const Title = styled.h2`
  text-align: center;
  font-size: 32px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 1.25rem;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    overflow-x: hidden;
  }
`;
