import React, { useContext, useEffect } from 'react';

import { CloseIcon } from '../../../assets/images/icons';
import {
  DeviceContext,
  SCREEN_SIZES,
} from '../../../providers/DetectDeviceProvider';

import * as S from './AuthModal.styles';

const AuthModal = ({ children, title, withBackgroundImage, onClose }) => {
  const device = useContext(DeviceContext);
  const isMobile = SCREEN_SIZES[device] < SCREEN_SIZES.tablet;

  useEffect(() => {
    document.documentElement.classList.add('dontScroll');

    return () => {
      document.documentElement.classList.remove('dontScroll');
    };
  }, []);

  return (
    <S.Wrapper>
      <S.CloseWrapper onClick={onClose} />
      <S.Inner withBackgroundImage={withBackgroundImage}>
        <S.Info withBackgroundImage={withBackgroundImage}>
          <S.Head>
            <S.Title>{title}</S.Title>
            <S.Close onClick={onClose}>
              <CloseIcon />
            </S.Close>
          </S.Head>
          {children}
        </S.Info>

        {withBackgroundImage && !isMobile ? <S.BackgroundImage /> : null}
      </S.Inner>
    </S.Wrapper>
  );
};

export default AuthModal;
