import React from 'react';
import { useParams } from 'react-router-dom';

import {
  BurgerMenuIcon,
  CasinoIcon,
  CloseIcon,
  SportIcon,
} from '../../../../assets/images/icons';

import { Icon, Item, Text, Wrapper } from './BottomMenu.styled';

const BottomMenu = ({ toggleMenu, isMenu }) => {
  const { lang } = useParams();
  return (
    <Wrapper>
      <Item
        to={`/${lang}/casino`}
        onClick={() => {
          toggleMenu(false);
        }}>
        <Icon>
          <CasinoIcon />
        </Icon>
        <Text>Casino</Text>
      </Item>
      <Item
        to={`/${lang}/sportsbook`}
        onClick={() => {
          toggleMenu(false);
        }}>
        <Icon>
          <SportIcon />
        </Icon>
        <Text>Sport</Text>
      </Item>
      <Item onClick={() => toggleMenu(prevState => !prevState)} as="div">
        <Icon>
          {isMenu ? (
            <CloseIcon fill="var(--sb-light-primary)" />
          ) : (
            <BurgerMenuIcon />
          )}
        </Icon>
        <Text>{isMenu ? 'Close' : 'More'}</Text>
      </Item>
    </Wrapper>
  );
};

export default BottomMenu;
