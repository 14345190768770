import styled from 'styled-components';

import { SCREEN_SIZES } from '../../../../providers/DetectDeviceProvider';

export const SearchLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  width: 100%;
  height: 100%;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    justify-content: center;
  }
`;

export const SearchIcon = styled.div`
  display: flex;
  align-items: center;
  width: 1.25rem;
  flex-shrink: 0;

  img {
    width: 100%;
  }
`;
export const SearchInput = styled.input`
  background-color: transparent;
  outline: none;
  width: 100%;
  flex-grow: 1;
  padding-left: 0.5rem;
`;
