import styled from 'styled-components';

import JackpotImage from '../../assets/images/jackpot/jackpot-bg.png';
import { SCREEN_SIZES } from '../../providers/DetectDeviceProvider';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${JackpotImage});
  background-size: cover;
  padding: 30px;
  border-radius: 20px;
  color: var(--sb-light-primary);
  width: 100%;
  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    max-width: 630px;
  }
`;

export const Title = styled.div`
  font-size: 1.25rem;
  letter-spacing: 1px;
`;
export const Amount = styled.div`
  font-size: 40px;
  text-shadow: 0 0 5px rgba(255, 214, 0, 0.5);
  margin-top: 0.5rem;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    margin-top: 0.75rem;
    font-size: 3.75rem;
  }

  @media screen and (min-width: ${SCREEN_SIZES.laptop}px) {
    margin-top: 1rem;
    font-size: 5rem;
  }
`;

export const Button = styled.div`
  width: 100%;
  max-width: 280px;
  margin-top: 2.5rem;
`;
