import styled from 'styled-components';

import userBackground from '../../../../assets/images/user/bg-profile.png';
import { SCREEN_SIZES } from '../../../../providers/DetectDeviceProvider';

export const Wrapper = styled.div`
  justify-content: space-between;
  flex-grow: 1;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    display: flex;
  }
`;

export const LeftSide = styled.div`
  background-image: url(${userBackground}),
    linear-gradient(149.84deg, #4414bc 6.68%, #6632e7);
  padding: 2rem 1.25rem;
  border-radius: 0.5rem;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    width: 35%;
  }
`;

export const Username = styled.div`
  font-size: 2rem;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #e4a324;
  text-shadow: 0 0 12px #2d0c7d;
`;

export const Balances = styled.div`
  margin-top: 1rem;
`;
export const Balance = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0.5rem 0;
  z-index: 1;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1.25rem;
    right: 1.25rem;
    background: #3f0e5e;
    box-shadow: inset 0 2px 4px #2a0231;
    border-radius: 5px;
    z-index: -1;
  }
`;
export const BalanceImage = styled.div`
  display: flex;

  img {
    width: 3.375rem;
  }
`;
export const BalanceInfo = styled.div`
  flex-grow: 1;
  padding-left: 2rem;
  color: var(--sb-active);
  font-size: 0.75rem;
`;

export const BalanceAmount = styled.div`
  font-size: 22px;
  color: var(--sb-light-primary);
  margin-top: 0.25rem;
`;

export const BalanceButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--sb-active);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  img {
    width: 1.5rem;
  }
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(149.84deg, #4414bc 6.68%, #6632e7);
  border-radius: 0.5rem;
  padding: 2rem 1.25rem;
  margin-top: 2rem;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    width: 60%;
    margin-top: 0;
  }
`;
export const Title = styled.div`
  font-size: 2rem;
  text-shadow: 0 0 12px #2d0c7d;
  color: var(--sb-light-primary);
`;

export const InputList = styled.div`
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  color: var(--sb-light-primary);
  margin-top: 1.75rem;
`;

export const InputField = styled.div`
  align-self: flex-start;
  width: 48%;
  margin-bottom: 1rem;
`;

export const Logout = styled.button`
  display: flex;
  align-items: center;
  margin-top: auto;
  cursor: pointer;
  padding: 0.5rem;
  background-color: transparent;
  border: none;
`;
export const LogoutIcon = styled.div`
  display: flex;
  img {
    width: 1.5rem;
  }
`;
export const LogoutText = styled.div`
  font-size: 16px;
  color: var(--sb-light-primary);
  text-transform: capitalize;
  margin-left: 0.5rem;
`;
