import React, { useState } from 'react';

import { features } from '../../../../../whiteLabelConfigs/whitelabelConfigs';
import * as S from '../../Tabs.styled';
import PaymentList from '../PaymentList';

import CreditCardDeposit from './components/CreditCardDeposit/CreditCardDeposit';
import CryptoDeposit from './components/CryptoDeposit/CryptoDeposit';
import NewCryptoDeposit from './components/NewCryptoDeposit/NewCryptoDeposit';

const components = {
  crypto: CryptoDeposit,
  creditCard: CreditCardDeposit,
  newCrypto: NewCryptoDeposit,
};

const Deposit = () => {
  const [selectedDeposit, setSelectedDeposit] = useState(
    features.deposit.list[0],
  );

  const Component = components[selectedDeposit.name];

  return (
    <S.Wrapper>
      <S.Methods>
        <S.Title>Select payment method</S.Title>
        <PaymentList
          list={features.deposit.list}
          onSelect={setSelectedDeposit}
          selectedItem={selectedDeposit}
        />
      </S.Methods>
      <S.Inner>
        <Component deposit={selectedDeposit} />
      </S.Inner>
    </S.Wrapper>
  );
};

export default Deposit;
