import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Label = styled.span`
  display: block;
  margin-bottom: 8px;
`;

export const Title = styled.span`
  color: var(--bw-text-color-base);
  display: block;
  font-size: 0.875rem;
  line-height: 1.5rem;
`;

export const Tabs = styled.div`
  display: flex;
  border-radius: 3px;
  background-color: var(--bw-neutral-color);
  padding: 5px;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const Tab = styled.button`
  width: calc(100% / 3);
  border-radius: 3px;
  color: var(--bw-text-color-base);
  font-size: 0.875rem;
  padding: 0.75rem 0.5rem;
  cursor: pointer;
  background-color: var(--bw-neutral-color);
  transition: filter 0.3s;

  ${props =>
    props.active
      ? css`
          background-color: var(--bw-active-color);
        `
      : css`
          &:hover:enabled {
            filter: brightness(1.3);
          }
        `};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: var(--bw-neutral-color);
  border-radius: 3px;
  margin-bottom: 1rem;
`;

export const DropDownSection = styled(Section)`
  flex-direction: row;
  justify-content: space-between;
  padding: 0.875rem;
  align-items: center;
`;

export const DropDownLeftSide = styled.div`
  display: flex;
`;

export const CryptoIconWrapper = styled.div`
  position: relative;
  display: flex;
  margin-right: 1rem;
  align-items: center;
`;

export const USDTRate = styled.div`
  margin-top: 1rem;
  font-size: 0.875rem;

  span {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const AddressBlock = styled.div`
  width: 100%;
`;

export const Wallet = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 0 1rem;
  border-radius: 5px;
  border: 3px solid rgb(155, 140, 215);
  background-color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 1rem;
  align-items: center;
`;

export const CopyButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  background-color: transparent;
  cursor: pointer;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: var(--bw-text-color-base);
    stroke: var(--bw-text-color-base);
  }
`;

export const QRWrapper = styled.div`
  width: 200px;
  height: 200px;
  padding: 0.75rem;
  background-color: #ffffff;
  border: 1px solid var(--bw-border-color-base);
  border-radius: 3px;
`;
