import React, { useEffect } from 'react';

import { CloseIcon } from '../../assets/images/icons';
import useQueryParams from '../../hooks/useQueryParams';

import { CloseButton, Content, Modal, Title, Wrapper } from './Modal.styles';

const ModalComponent = ({ children, title }) => {
  const { deleteParam } = useQueryParams();

  useEffect(() => {
    document.documentElement.classList.add('dontScroll');

    return () => {
      document.documentElement.classList.remove('dontScroll');
    };
  }, []);

  return (
    <Wrapper onClick={() => deleteParam('popup')}>
      <Modal onClick={e => e.stopPropagation()}>
        <CloseButton onClick={() => deleteParam('popup')}>
          <CloseIcon />
        </CloseButton>
        {title ? <Title>{title}</Title> : null}
        <Content>{children}</Content>
      </Modal>
    </Wrapper>
  );
};

export default ModalComponent;
