import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import useQueryParams from '../../../hooks/useQueryParams';
import useDisableScroll from '../../../providers/useDisableScroll';
import useSignUp from '../../../providers/useSignUp';
import axiosInstance from '../../../services/interceptor';
import { features } from '../../../whiteLabelConfigs/whitelabelConfigs';
import Button from '../../ui/Button/Button';
import Input from '../../ui/Input/Input';
import { Form, FormButton, FormItem, Subtitle } from '../Auth.styles';
import AuthModal from '../AuthModal/AuthModal';
import AuthSuccessfully from '../AuthSuccessfully';

const formSchema = Yup.object().shape({
  username: Yup.string()
    .required('Username is required')
    .min(3, 'Username must be at 3 char long'),

  email: Yup.string()
    .required('Email is required')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Email is not valid'),

  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at 6 char long'),
  confirmPassword: Yup.string()
    .required('New Password is required')
    .oneOf([Yup.ref('password')], 'Passwords does not match'),
});

const Register = () => {
  const { setSearchParams } = useQueryParams();
  const [errorList, setErrorList] = useState([]);
  const [isSuccessFully, setIsSuccessFully] = useState(false);
  const [loading, setLoading] = useState(false);
  const { deleteParam } = useQueryParams();

  useDisableScroll();

  const formOptions = {
    resolver: yupResolver(formSchema),
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm(formOptions);

  const [email, username] = watch(['email', 'username']);

  const { customFields } = useSignUp({
    username,
    email,
  });

  const onSubmit = data => {
    if (
      Object.values(customFields).some(field => !!field.error || field.loading)
    ) {
      return;
    }
    setLoading(true);

    const newData = {
      ...data,
      conditions: undefined,
    };
    axiosInstance
      .post(`${features.auth.requestVersion}/sign-up`, newData)
      .then(() => setIsSuccessFully(true))
      .catch(setErrorList)
      .finally(() => setLoading(false));
  };

  return (
    <AuthModal
      title={'Sign Up'}
      withBackgroundImage
      onClose={() => deleteParam('popup')}>
      {!isSuccessFully && <Subtitle>Sign up and get your first bonus</Subtitle>}
      {isSuccessFully ? (
        <AuthSuccessfully text="We have sent a verification code to your email address!" />
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormItem>
            <Input
              error={errors.email || customFields.email.error}
              loading={customFields.email.loading}
              success={customFields.email.available}
              title={'Email'}
              placeholder={'Email'}
              type={'email'}
              register={register('email')}
            />
          </FormItem>
          <FormItem>
            <Input
              error={errors.username || customFields.username.error}
              loading={customFields.username.loading}
              success={customFields.username.available}
              title={'Username'}
              placeholder={'Username'}
              register={register('username')}
            />
          </FormItem>
          <FormItem>
            <Input
              error={errors.password}
              title={'Password'}
              placeholder={'Password'}
              type={'password'}
              register={register('password')}
            />
          </FormItem>
          <FormItem>
            <Input
              error={errors.confirmPassword}
              title={'Password Confirmation'}
              placeholder={'Password Confirmation'}
              type={'password'}
              register={register('confirmPassword')}
            />
          </FormItem>
          <FormButton>
            <Button
              loading={loading}
              type={'submit'}
              text={'Create Account'}
              fill={'var(--sb-active)'}
              color={'var(--sb-active-contrast)'}
              big
            />
          </FormButton>
          <FormButton>
            <Button
              type="button"
              text={'Log In'}
              color={'var(--sb-light-primary)'}
              stroke={'var(--sb-light-primary)'}
              big
              onClick={() => setSearchParams({ popup: 'sign-in' })}
            />
          </FormButton>
          {errorList?.map(item => (
            <div key={item.message} style={{ color: 'red', fontSize: 12 }}>
              {item.message}
            </div>
          ))}
        </Form>
      )}
    </AuthModal>
  );
};

export default Register;
