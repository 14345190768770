import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import backgroundImage from '../../assets/images/home/bg-star.png';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-image: url(${backgroundImage}),
    linear-gradient(165.49deg, #222a5b 1.25%, #0e1436);
  background-size: cover;
  padding: 2rem 0;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 1.75rem;
  color: var(--sb-light-primary);
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 2rem 0;
  h4 {
    color: var(--sb-active);
  }
`;

export const Tab = styled(NavLink)`
  font-weight: 900;
  font-size: 1rem;
  color: #616577;
  cursor: pointer;
  text-transform: capitalize;
  text-decoration: none;

  &.active {
    color: var(--sb-active);
    border-bottom: 2px solid;
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 75rem;
  margin: 0 auto;
  flex-grow: 1;
`;
