import { Link, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { SCREEN_SIZES } from '../../../../providers/DetectDeviceProvider';

export const Wrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  padding-bottom: 4.72rem;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    padding-bottom: 0;
  }
`;

export const WrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  transition: 0.3s;
  background: linear-gradient(165.49deg, #222a5b 1.25%, #0e1436);
  ${({ active }) =>
    active &&
    css`
      transform: translateX(0);
    `};

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    max-width: 15.625rem;
    background: rgba(38, 37, 91, 0.9);
  }
`;

export const WrapperClose = styled.div`
  flex-grow: 1;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const LogoLink = styled(Link)`
  padding: 1.5rem 0;
  width: 10rem;

  img {
    width: 100%;
  }
`;

export const CloseButton = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  cursor: pointer;

  img,
  svg {
    width: 100%;
    height: 100%;
  }

  svg {
    fill: var(--sb-light-primary);
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.25rem;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    flex-direction: column;
  }
`;
export const Button = styled.div`
  width: calc((100% - 1rem) / 2);

  &:not(:last-child) {
    margin-right: 1rem;
  }

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 1rem;
      margin-right: 0;
    }
  }
`;

export const Nav = styled.div`
  margin-top: 1.25rem;
  padding: 0 1.25rem;
`;
export const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #4f5992;
  padding: 1rem 0;
  font-size: 0.75rem;
  color: var(--sb-light-primary);
  text-decoration: none;
`;
export const NavIcon = styled.div`
  width: 1.5rem;
  margin-right: 0.5rem;

  img {
    width: 100%;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--sb-light-primary);
  }
`;
export const NavText = styled.div``;
export const NavArrow = styled.div`
  width: 0.75rem;
  margin-left: auto;

  img {
    width: 100%;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: auto;
  padding: 1rem;
  background: rgb(50 55 82);
  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    justify-content: space-between;
    background: var(--sb-active-contrast);
  }
`;
export const Support = styled.div`
  width: 9.4375rem;
  margin-right: 1.5rem;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    margin-right: 0;
  }
`;

export const User = styled.div`
  width: 100%;
  max-width: 15rem;
  padding: 0 1.25rem;
  margin: 0 auto;

  ${Button} {
    width: 100%;
  }
`;
export const Balance = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: var(--sb-light-primary);
  margin-bottom: 1rem;
  text-decoration: none;
`;
export const BalanceIcon = styled.div`
  width: 3rem;
  img {
    width: 100%;
  }
`;

export const BalanceAmount = styled.div`
  margin-left: 2rem;
  text-align: center;
`;
