import React from 'react';
import { Outlet } from 'react-router-dom';

import Types from './components/Types/Types';
import { TypesField, Wrapper } from './Casino.styled';

const Casino = ({ section }) => {
  return (
    <Wrapper>
      <TypesField>
        <Types section={section} />
      </TypesField>
      <Outlet />
    </Wrapper>
  );
};

export default Casino;
