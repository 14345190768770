import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 1.25rem;

  h4 {
    color: #616577;
  }
`;

export const Tab = styled.div`
  font-weight: 900;
  font-size: 20px;
  color: #616577;
  cursor: pointer;
  padding-bottom: 4px;
  text-transform: capitalize;

  ${props =>
    props.active &&
    css`
      color: #f69220;
      border-bottom: 2px solid;
    `}
`;
