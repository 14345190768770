import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 4;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 0.25rem;
`;
export const Flag = styled.div`
  width: 1.75rem;
  img {
    width: 100%;
  }
`;
export const Arrow = styled.div`
  display: flex;
  margin-left: 0.5rem;

  svg {
    width: 0.625rem;
    height: 0.625rem;
    transform: scaleY(1);
    transition: 0.3s;
    color: var(--sb-light-primary);
  }

  ${({ active }) =>
    active &&
    css`
      svg {
        transform: scaleY(-1);
      }
    `}
`;

export const Body = styled.div`
  position: absolute;
  bottom: -0.5rem;
  right: 0;
  width: 10rem;
  transform: translateY(100%);
  background-color: rgba(38, 37, 91, 0.9);
  padding: 1.125rem 1rem 0.5rem;
  border-radius: 0.625rem;
  z-index: 2;
  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 20px;
    transform: rotate(180deg);
    border-width: 10px 20px 10px 0;
    border-style: solid;
    border-color: rgba(38, 37, 91, 0.9) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0)
      rgba(0, 0, 0, 0);
  }

  ${({ toTop }) =>
    toTop &&
    css`
      bottom: auto;
      left: 50%;
      top: -0.5rem;
      transform: translate(-50%, -100%);

      &::after {
        content: '';
        position: absolute;
        bottom: auto;
        top: 100%;
        right: auto;
        left: 50%;
        transform: rotate(0deg);
        border-width: 10px 20px 10px 0;
      }
    `}
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  width: calc((100% - 2.5rem) / 3);
  margin-bottom: 10px;
  cursor: pointer;

  :not(:nth-child(3n)) {
    margin-right: 20px;
  }
`;

export const Close = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
`;
