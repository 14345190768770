import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 3;
  background-color: var(--sb-active-contrast);
`;

export const Item = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% / 3);
  color: var(--sb-light-primary);
  padding: 1rem 0;
  text-decoration: none;

  &.active {
    color: var(--sb-active);
  }
`;
export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;

  img,
  svg {
    width: 100%;
    height: 100%;
  }
`;
export const Text = styled.div`
  font-size: 0.625rem;
  margin-top: 0.5rem;
`;
