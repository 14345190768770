import React, { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useSelector } from 'react-redux';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import PropTypes from 'prop-types';

import { getSupportedLanguages } from '../store/global/global.selector';
import store from '../store/store';

import {
  ChinaFlag,
  FranceFlag,
  GreatBritainFlag,
  IranFlag,
  KoreaFlag,
  RussiaFlag,
  SpainFlag,
  SwedenFlag,
  TurkeyFlag,
  UaeFlag,
} from '../assets/images/flags';
import SuspenseComponent from '../components/SuspenseComponent/SuspenseComponent';

export const LANGUAGES = {
  fr: {
    key: 'fr',
    title: 'French',
    icon: FranceFlag,
    dir: 'ltr',
  },
  en: {
    key: 'en',
    title: 'English',
    icon: GreatBritainFlag,
    dir: 'ltr',
  },
  ar: {
    key: 'ar',
    title: 'Arabic',
    icon: UaeFlag,
    dir: 'rtl',
  },
  cn: {
    key: 'cn',
    title: 'Chinese',
    icon: ChinaFlag,
    dir: 'ltr',
  },
  es: {
    key: 'es',
    title: 'Spanish',
    icon: SpainFlag,
    dir: 'ltr',
  },
  fa: {
    key: 'fa',
    title: 'Farsi',
    icon: IranFlag,
    dir: 'rtl',
  },
  ko: {
    key: 'ko',
    title: 'Korean',
    icon: KoreaFlag,
    dir: 'ltr',
  },
  ru: {
    key: 'ru',
    title: 'Russian',
    icon: RussiaFlag,
    dir: 'ltr',
  },
  tr: {
    key: 'tr',
    title: 'Turkish',
    icon: TurkeyFlag,
    dir: 'ltr',
  },
  se: {
    key: 'se',
    title: 'Sweden',
    icon: SwedenFlag,
    dir: 'ltr',
  },
};

export const langFromUrl = location.pathname.split('/')[1];
const langFromLocalStorage = localStorage.getItem('lang');

export function getDefaultLanguage(supportedLangs) {
  const supportedLanguages =
    supportedLangs || store.getState().global.supportedLanguages;

  if (langFromUrl && supportedLanguages.includes(langFromUrl)) {
    return langFromUrl;
  } else if (
    langFromLocalStorage &&
    supportedLanguages.includes(langFromLocalStorage)
  ) {
    return langFromLocalStorage;
  } else {
    return supportedLanguages[0];
  }
}

export const t = i18n.t.bind(i18n);

function MyI18nextProvider({ children }) {
  const supportedLanguages = useSelector(getSupportedLanguages);
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (supportedLanguages.length) {
      const defaultLang = getDefaultLanguage(supportedLanguages);

      i18n.use(Backend).init({
        lng: defaultLang,
        supportedLngs: supportedLanguages,
        ns: ['translation', 'markets', 'betSlip'],
        defaultNS: 'translation',
        interpolation: {
          escapeValue: false, // not needed for react!!
        },
        backend: {
          loadPath: `${process.env.REACT_APP_DICTIONARY_URL}/public/{{lng}}/{{ns}}.json`,
        },
      });

      setInit(true);
    }
  }, [supportedLanguages]);

  return init ? (
    <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
  ) : (
    <SuspenseComponent />
  );
}

export default MyI18nextProvider;

MyI18nextProvider.propTypes = {
  children: PropTypes.node,
};
