import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { authSelectors } from '../../../../store/auth/auth.selectors';

import {
  CasinoIcon,
  CloseIcon,
  SportIcon,
} from '../../../../assets/images/icons';
import arrowImage from '../../../../assets/images/icons/arrow-right.svg';
import loginIcon from '../../../../assets/images/icons/login.svg';
import paymentImage from '../../../../assets/images/icons/payment.svg';
import RegisterIcon from '../../../../assets/images/icons/register.svg';
import supportIcon from '../../../../assets/images/icons/support.svg';
import balanceImage from '../../../../assets/images/user/balance.png';
import LanguageSelection from '../../../../components/LanguageSelection/LanguageSelection';
import Button from '../../../../components/ui/Button/Button';
import useQueryParams from '../../../../hooks/useQueryParams';
import {
  DeviceContext,
  SCREEN_SIZES,
} from '../../../../providers/DetectDeviceProvider';
import { features } from '../../../../whiteLabelConfigs/whitelabelConfigs';

import * as S from './MobileMenu.styled';

const MobileMenu = ({ active, toggleMenu }) => {
  const [isAnimation, setIsAnimation] = useState(false);
  const device = useContext(DeviceContext);
  const isMobile = SCREEN_SIZES[device] < SCREEN_SIZES.tablet;
  const isLoggedIn = useSelector(authSelectors.selectIsLoggedIn);
  const user = useSelector(authSelectors.selectUser);
  const { setSearchParams } = useQueryParams();
  const { lang } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    document.documentElement.classList.add('dontScroll');

    return () => {
      document.documentElement.classList.remove('dontScroll');
    };
  }, []);

  useEffect(() => {
    setIsAnimation(active);
  }, [active]);

  return (
    <S.Wrapper>
      <S.WrapperInner active={isAnimation}>
        <S.Logo>
          <S.LogoLink to={''}>
            <img src={features.logo} alt="" />
          </S.LogoLink>
          <S.CloseButton onClick={() => toggleMenu(false)}>
            <CloseIcon />
          </S.CloseButton>
        </S.Logo>
        {isLoggedIn ? (
          <>
            <S.User>
              <S.Balance
                to={`/${lang}/account/`}
                onClick={() => toggleMenu(false)}>
                <S.BalanceIcon>
                  <img src={balanceImage} alt="" />
                </S.BalanceIcon>
                <S.BalanceAmount>
                  <div>Balance</div>
                  <div>€ {user.balance.toTruncFixed()}</div>
                </S.BalanceAmount>
              </S.Balance>
              <S.Button>
                <Button
                  image={paymentImage}
                  fill={'var(--sb-active)'}
                  color={'var(--sb-active-contrast)'}
                  text={'Payment'}
                  onClick={() => {
                    setSearchParams({ popup: 'payment' });
                    toggleMenu(false);
                  }}
                />
              </S.Button>
            </S.User>
          </>
        ) : (
          <S.Buttons>
            <S.Button>
              <Button
                image={loginIcon}
                stroke={'var(--sb-light-primary)'}
                text={isMobile ? '' : t('login')}
                onClick={() => {
                  setSearchParams({ popup: 'sign-in' });
                  toggleMenu(false);
                }}
              />
            </S.Button>
            {features.auth.withRegistration && (
              <S.Button>
                <Button
                  image={RegisterIcon}
                  fill={'var(--sb-active)'}
                  color={'var(--sb-active-contrast)'}
                  text={isMobile ? '' : t('register')}
                  onClick={() => {
                    setSearchParams({ popup: 'sign-up' });
                    toggleMenu(false);
                  }}
                />
              </S.Button>
            )}
          </S.Buttons>
        )}
        <S.Nav>
          <S.NavItem to={`/${lang}/casino/`} onClick={() => toggleMenu(false)}>
            <S.NavIcon>
              <CasinoIcon />
            </S.NavIcon>
            <S.NavText>{t('casino.name')}</S.NavText>
            <S.NavArrow>
              <img src={arrowImage} alt="" />
            </S.NavArrow>
          </S.NavItem>
          <S.NavItem
            to={`/${lang}/sportsbook`}
            onClick={() => toggleMenu(false)}>
            <S.NavIcon>
              <SportIcon />
            </S.NavIcon>
            <S.NavText>{t('sport')}</S.NavText>
            <S.NavArrow>
              <img src={arrowImage} alt="" />
            </S.NavArrow>
          </S.NavItem>
        </S.Nav>
        <S.Footer>
          <S.Support>
            <Button
              text={'Online Support'}
              image={supportIcon}
              fill={'var(--sb-light-secondary)'}
              color={'var(--sb-active-contrast)'}
            />
          </S.Support>
          <LanguageSelection toTop />
        </S.Footer>
      </S.WrapperInner>
      <S.WrapperClose onClick={() => toggleMenu(false)} />
    </S.Wrapper>
  );
};

export default MobileMenu;
