import styled, { css } from 'styled-components';

export const Wrapper = styled.label`
  display: block;
  position: relative;
  width: 100%;

  input {
    width: 100%;
    height: 48px;
    padding: 1rem 3rem 1rem 1rem;
    border-radius: 5px;
    border: 3px solid #9b8cd7;
    background-color: var(--sb-light-primary);
    font-weight: 700;
    font-size: 1rem;
    color: #161c3d;

    &:read-only {
      opacity: 0.8;
    }

    ${props =>
      props.error &&
      css`
        border-color: red;
      `}
  }
`;

export const InputWrapper = styled.span`
  display: block;
  position: relative;
`;
export const Icons = styled.div`
  display: flex;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
`;
export const Icon = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 0.5rem;
  }

  svg {
    width: 1.75rem;
    height: 1.75rem;
  }
`;

export const Title = styled.span`
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
  text-transform: capitalize;
`;
