import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Turnstile from 'react-turnstile';

import { fetchSignIn, fetchUser } from '../../../store/auth/auth.slice';

import useQueryParams from '../../../hooks/useQueryParams';
import { features } from '../../../whiteLabelConfigs/whitelabelConfigs';
import Button from '../../ui/Button/Button';
import Input from '../../ui/Input/Input';
import SmallLoader from '../../ui/SmallLoader/SmallLoader';
import { Form, FormButton, FormItem } from '../Auth.styles';
import AuthModal from '../AuthModal/AuthModal';

import { Forgot, Loader } from './Login.styled';

const Login = () => {
  const { deleteParam, setSearchParams } = useQueryParams();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [recaptchaKey, setRecaptchaKey] = useState(1);
  const [init, setInit] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const [recaptchaToken] = watch(['cf-turnstile-response']);

  const onSubmit = data => {
    if (loading) {
      return;
    }

    setLoading(true);
    setError(null);

    dispatch(fetchSignIn(data))
      .unwrap()
      .then(() => {
        dispatch(fetchUser())
          .unwrap()
          .then(() => {
            deleteParam('popup');
          });
        setLoading(false);
      })
      .catch(e => {
        setRecaptchaKey(prev => prev + 1);
        // setValue('cf-turnstile-response', null);
        setError(e.message || 'The username or password is incorrect');
      });
  };

  return (
    <AuthModal
      title={'Authorization'}
      withBackgroundImage
      onClose={() => deleteParam('popup')}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {!init ? (
          <Loader>
            <SmallLoader color="var(--sb-active)" />
          </Loader>
        ) : (
          <>
            <FormItem>
              <Input
                title={'Login'}
                placeholder={'Login'}
                error={'username' in errors}
                register={register('username', {
                  required: 'Username is required',
                })}
              />
            </FormItem>
            <FormItem>
              <Input
                title={'Password'}
                placeholder={'Password'}
                type={'password'}
                error={'password' in errors}
                register={register('password', {
                  required: 'Password is required',
                  minLength: 6,
                })}
              />
            </FormItem>
            <Forgot
              onClick={() => setSearchParams({ popup: 'forgot-password' })}>
              Forgot Password?
            </Forgot>
            <FormButton>
              <Button
                disabled={!recaptchaToken}
                type="submit"
                text={'Log In'}
                fill={'var(--sb-active)'}
                color={'var(--sb-active-contrast)'}
                big
                loading={loading}
              />
              {!loading && error && <span>{error}</span>}
            </FormButton>
            {features.auth.withRegistration && (
              <FormButton>
                <Button
                  type={'button'}
                  text={'Create Account'}
                  stroke={'var(--sb-light-primary)'}
                  color={'var(--sb-light-primary)'}
                  big
                  onClick={() => setSearchParams({ popup: 'sign-up' })}
                />
              </FormButton>
            )}
          </>
        )}

        <Controller
          name="cf-turnstile-response"
          control={control}
          rules={{ required: true }}
          render={props => (
            <Turnstile
              key={recaptchaKey}
              autoResetOnExpire
              size="invisible"
              sitekey="0x4AAAAAAAEoC8GV5RSElm6G"
              onVerify={token => {
                props.field.onChange(token);
                setLoading(false);
                setInit(true);
              }}
            />
          )}
        />
      </Form>
    </AuthModal>
  );
};

export default Login;
