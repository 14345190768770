import React, { useRef, useState } from 'react';

import {
  CloseIcon,
  ErrorIcon,
  EyeCloseIcon,
  EyeOpenIcon,
  SuccessIcon,
} from '../../../assets/images/icons';
import Spinner from '../Spinner';

import { Icon, Icons, InputWrapper, Title, Wrapper } from './Input.styled';

const Input = ({
  title,
  placeholder,
  type = 'text',
  error,
  register,
  value,
  readonly,
  loading,
  success,
}) => {
  const ref = useRef(null);
  const [valueHidden, setValueHidden] = useState(type === 'password');

  return (
    <Wrapper error={error}>
      <Title>{title}</Title>
      <InputWrapper>
        <input
          type={valueHidden ? type : 'text'}
          ref={ref}
          placeholder={placeholder}
          value={value}
          readOnly={readonly}
          {...register}
        />
        <Icons>
          {type === 'password' && (
            <Icon
              type={'button'}
              onClick={() => setValueHidden(prevState => !prevState)}>
              {valueHidden ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </Icon>
          )}
          {loading && (
            <Icon as="div" style={{ cursor: 'auto' }}>
              <Spinner />
            </Icon>
          )}
          {success && (
            <Icon as="div" style={{ cursor: 'auto' }}>
              <SuccessIcon fill="#00a100" />
            </Icon>
          )}
          {error && (
            <Icon as="div" style={{ cursor: 'auto' }}>
              <ErrorIcon fill="red" />
            </Icon>
          )}
        </Icons>
      </InputWrapper>
    </Wrapper>
  );
};

export default Input;
