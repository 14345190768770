import { createGlobalStyle } from 'styled-components';

import { cssVariables } from './cssVariables';
import { globalStyles } from './global.styles';
import { normalizeStyles } from './normalize';

import './font.css';

export const GlobalStyledProvider = createGlobalStyle`
    ${normalizeStyles}
    
    ${globalStyles}

    ${cssVariables}
`;
