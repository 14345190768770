import React, { useEffect, useState } from 'react';
import capitalize from 'lodash/capitalize';

import axiosInstance from '../../../../../../../services/interceptor';
import Button from '../../../../../../ui/Button/Button';
import MessagePopup from '../../../../../../ui/Popups/MessagePopup';
import SmallLoader from '../../../../../../ui/SmallLoader/SmallLoader';
import * as S from '../../../../Tabs.styled';

import CreateWallet from './CreateWallet';
import CryptoWalletList from './CryptoWalletList';
import EmptyWallet from './EmptyWallet';

const CryptoDeposit = () => {
  const [wallets, setWallets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [createWalletView, setCreateWalletView] = useState(false);
  const [popupMessage, setPopupMessage] = useState(null);

  function getWallets() {
    setLoading(true);

    axiosInstance
      .get('crypto/wallets')
      .then(setWallets)
      .finally(() => {
        setLoading(false);
        setCreateWalletView(false);
      });
  }

  useEffect(() => {
    getWallets();
  }, []);

  return (
    <>
      {loading && !wallets.length ? (
        <SmallLoader />
      ) : (
        <>
          {!createWalletView &&
            (wallets.length ? (
              <CryptoWalletList wallets={wallets} />
            ) : (
              <EmptyWallet
                title="You don't have any wallet yet!"
                text="In order to refill your balance you need to create your wallet with your preferred cryptocurrency and send the crypto amount to your respective wallet. Your wallet will be refilled automatically."></EmptyWallet>
            ))}

          {createWalletView ? (
            <CreateWallet
              onBack={() => setCreateWalletView(false)}
              onFinished={() => {
                getWallets();
                setPopupMessage({
                  type: 'success',
                  message: 'Wallet created successfully!',
                });
              }}
              onError={message =>
                setPopupMessage({
                  type: 'error',
                  message: capitalize(message),
                })
              }
            />
          ) : (
            <S.Button style={{ width: '100%' }}>
              <Button
                text={'Create Wallet'}
                fill={'#3db860'}
                color={'var(--sb-light-primary)'}
                big
                type="submit"
                onClick={() => setCreateWalletView(true)}
              />
            </S.Button>
          )}
        </>
      )}

      {popupMessage && (
        <MessagePopup
          type={popupMessage.type}
          message={popupMessage.message}
          onClose={() => setPopupMessage(false)}
        />
      )}
    </>
  );
};

export default CryptoDeposit;
