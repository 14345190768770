import styled, { css } from 'styled-components';

import { SCREEN_SIZES } from '../../providers/DetectDeviceProvider';

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const OutletField = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${({ isSportsbookPage }) =>
    isSportsbookPage &&
    css`
      padding-bottom: 4.72rem;
      @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
        padding-bottom: 0;
      }
    `}
`;
