import { createSelector } from '@reduxjs/toolkit';
const selectAuth = state => state.auth;

const selectIsLoggedIn = createSelector([selectAuth], auth => auth.isLoggedIn);

const selectError = createSelector([selectAuth], auth => auth.error);

const selectLoading = createSelector([selectAuth], auth => auth.loading);

const selectUser = createSelector([selectAuth], auth => auth.user);

const selectAccessToken = createSelector(
  [selectAuth],
  auth => auth.accessToken,
);

export const authSelectors = {
  selectIsLoggedIn,
  selectError,
  selectLoading,
  selectUser,
  selectAccessToken,
};
