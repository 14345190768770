import axios from 'axios';

import { authActions } from '../store/auth/auth.slice';
import store from '../store/store';

const cancels = new Map();

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api`,
});

axiosInstance.interceptors.request.use(
  function (config) {
    const accessToken = store.getState().auth.accessToken;

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    const { url, method } = config;

    if (method === 'get' || method === 'GET') {
      const cancel = cancels.get(`${method}:${url}`);

      if (cancel) {
        cancel();
      }

      config.cancelToken = new axios.CancelToken(cancel => {
        cancels.set(`${method}:${url}`, cancel);
      });
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  function (response) {
    const { url, method } = response.config;
    if (method === 'get' || method === 'GET') {
      cancels.delete(`${method}:${url}`);
    }
    return response.data;
  },
  function (error) {
    if (error.response?.config) {
      const { url, method } = error.response.config;
      if (method === 'get' || method === 'GET') {
        cancels.delete(`${method}:${url}`);
      }
    }

    if ((error.status || error.response?.status) === 401) {
      store.dispatch(authActions.logout());
    }

    return Promise.reject(error.response.data);
  },
);

export default axiosInstance;
