import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authSelectors } from '../../../../store/auth/auth.selectors';
import { authActions } from '../../../../store/auth/auth.slice';

import paymentImage from '../../../../assets/images/icons/payment.svg';
import balanceImage from '../../../../assets/images/user/balance.png';
import logoutImage from '../../../../assets/images/user/log-out.png';
import Input from '../../../../components/ui/Input/Input';
import useQueryParams from '../../../../hooks/useQueryParams';

import {
  Balance,
  BalanceAmount,
  BalanceButton,
  BalanceImage,
  BalanceInfo,
  Balances,
  InputField,
  InputList,
  LeftSide,
  Logout,
  LogoutIcon,
  LogoutText,
  RightSide,
  Title,
  Username,
  Wrapper,
} from './MyAccount.styled';

const MyAccount = () => {
  const user = useSelector(authSelectors.selectUser);
  const { setSearchParams } = useQueryParams();
  const dispatch = useDispatch();
  return (
    <Wrapper>
      <LeftSide>
        <Username>{user.username}</Username>
        <Balances>
          <Balance>
            <BalanceImage>
              <img src={balanceImage} alt="" />
            </BalanceImage>
            <BalanceInfo>
              <div>Balance</div>
              <BalanceAmount>$ {user.balance.toTruncFixed()}</BalanceAmount>
            </BalanceInfo>
            <BalanceButton
              onClick={() => setSearchParams({ popup: 'payment' })}>
              <img src={paymentImage} alt="" />
            </BalanceButton>
          </Balance>
        </Balances>
      </LeftSide>
      <RightSide>
        <Title>Account details</Title>
        <InputList>
          <InputField>
            <Input
              placeholder={'username'}
              title={'username'}
              value={user.username}
              readonly
            />
          </InputField>
          <InputField>
            <Input
              placeholder={'email'}
              title={'email'}
              value={user.email}
              readonly
            />
          </InputField>
          <InputField>
            <Input placeholder={'id'} title={'id'} value={user.id} readonly />
          </InputField>
          <InputField>
            <Input
              placeholder={'currency'}
              title={'currency'}
              value={user.currency}
              readonly
            />
          </InputField>
        </InputList>
        <Logout onClick={() => dispatch(authActions.logout())}>
          <LogoutIcon>
            <img src={logoutImage} alt="" />
          </LogoutIcon>
          <LogoutText>logout</LogoutText>
        </Logout>
      </RightSide>
    </Wrapper>
  );
};

export default MyAccount;
