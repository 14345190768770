import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalComponent from '../Modal/Modal';

import { DepositTab, HistoryTab, WithdrawTab } from './Tabs/Tabs';
import { Tab, Tabs, Wrapper } from './Payment.styles';

const TABS = [
  { name: 'deposit', component: DepositTab },
  { name: 'withdraw', component: WithdrawTab },
  { name: 'history', component: HistoryTab },
];

const Payments = () => {
  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const { t } = useTranslation();

  const ContentComponent = selectedTab.component;

  return (
    <ModalComponent title={'Payment'}>
      <Wrapper>
        <Tabs>
          {TABS.map(tab => (
            <Tab
              key={tab.name}
              active={selectedTab.name === tab.name}
              onClick={() => setSelectedTab(tab)}>
              {t(tab.name)}
            </Tab>
          ))}
        </Tabs>
        {<ContentComponent />}
      </Wrapper>
    </ModalComponent>
  );
};

export default Payments;
