import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
  }
`;

const LazyImage = ({
  image,
  alt = 'image',
  errorView,
  loadView,
  size = '100%',
  objectFit = 'contain',
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  return (
    <Wrapper
      style={{
        width: size,
        height: size,
      }}>
      {!loaded && !error ? loadView : null}
      {error ? errorView : null}
      <img
        style={{
          objectFit,
          display: !loaded || error ? 'none' : 'unset',
        }}
        src={image}
        alt={alt}
        onLoad={() => setLoaded(true)}
        onError={() => setError(true)}
      />
    </Wrapper>
  );
};

export default LazyImage;
