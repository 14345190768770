import React from 'react';

import useQueryParams from '../../hooks/useQueryParams';

import Login from './Login/Login';
import Register from './Register/Register';
import ForgotPassword from './ForgotPassword';

const components = {
  'sign-in': Login,
  'sign-up': Register,
  'forgot-password': ForgotPassword,
};

const Auth = () => {
  const { searchParams } = useQueryParams();
  const { popup } = searchParams;

  const Component = components[popup];

  if (!Component) {
    return null;
  }

  return <Component />;
};

export default Auth;
