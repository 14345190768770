import styled from 'styled-components';

import backgroundImage from '../../assets/images/home/bg-star.png';
import { SCREEN_SIZES } from '../../providers/DetectDeviceProvider';

export const Wrapper = styled.div`
  flex-grow: 1;
  background-image: url(${backgroundImage}),
    linear-gradient(165.49deg, #222a5b 1.25%, #0e1436);
  background-size: cover;
  padding: 2rem 1rem 1rem;
  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    padding: 3rem 2rem 2rem;
  }
`;

export const TypesField = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
`;
