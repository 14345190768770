import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';

import { authSelectors } from '../store/auth/auth.selectors';
import { authActions, fetchUser } from '../store/auth/auth.slice';

import useQueryParams from '../hooks/useQueryParams';

export default function AuthProvider({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector(authSelectors.selectAccessToken);
  const { pathname } = useLocation();
  const { searchParams, deleteParam } = useQueryParams();
  const { popup } = searchParams;

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchUser())
        .then(unwrapResult)
        .then(() => {
          if (pathname.includes('auth')) {
            navigate('/');
          } else if (popup === 'sign-in' || popup === 'sign-up') {
            deleteParam('popup');
          }
        });
    } else {
      if (popup === 'payment') {
        deleteParam('popup');
      }

      dispatch(
        authActions.init({
          isInitialised: true,
          isLoggedIn: false,
          user: null,
        }),
      );
    }
  }, [accessToken]);

  return <>{children}</>;
}
