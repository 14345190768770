import React from 'react';

import * as S from '../Tabs.styled';

const PaymentList = ({ list, onSelect, selectedItem }) => {
  return (
    <S.MethodsList>
      {list.map(item => (
        <S.MethodsItem
          onClick={() => onSelect(item)}
          key={item.name}
          title={item.name}
          active={selectedItem.name === item.name}>
          <S.MethodsIcon>
            <img src={item.image} alt="" />
          </S.MethodsIcon>
          <S.MethodsInfo>
            $ {item.minAmount} - $ {item.maxAmount}
          </S.MethodsInfo>
        </S.MethodsItem>
      ))}
    </S.MethodsList>
  );
};

export default PaymentList;
