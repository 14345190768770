import styled from 'styled-components';

import backgroundImage from '../../assets/images/home/bg-star.png';
import bottomBackgroundImage from '../../assets/images/home/bottomBackground.jpg';
import mainBackgroundImage from '../../assets/images/home/main-bg.png';
import manBackgroundImage from '../../assets/images/home/man.png';
import { SCREEN_SIZES } from '../../providers/DetectDeviceProvider';

export const Wrapper = styled.div`
  background-color: var(--sb-background-primary);

  @media screen and (min-width: ${SCREEN_SIZES.laptop}px) {
    padding: 0 5rem 0;
  }
`;

export const WrapperInner = styled.div`
  background-image: url(${backgroundImage}),
    linear-gradient(165.49deg, #222a5b 1.25%, #0e1436);
  background-size: cover;
`;

export const Welcome = styled.div`
  background-image: url(${manBackgroundImage}), url(${mainBackgroundImage});
  background-position: left top, bottom center;
  background-size: 250px, cover;
  background-repeat: no-repeat;
  padding: 6rem 5rem 8rem;
  text-align: center;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    padding: 8rem 5rem 10rem;
    background-size: 20rem, cover;
    background-position: 10% top, bottom center;
  }

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    padding: 13rem 5rem 15rem;
    background-size: 25rem, cover;
    background-position: 20% top, bottom center;
  }
`;

export const WelcomeText = styled.div`
  font-size: 1rem;
  color: var(--sb-light-primary);

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    font-size: 1.125rem;
  }
`;
export const WelcomeTitle = styled.div`
  margin-top: 0.5rem;
  background: linear-gradient(
    174deg,
    var(--sb-active) 50%,
    #f8d43a 0,
    #e4ad12 0,
    #de9405 20%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  font-weight: 900;
  font-size: 2rem;
  font-family: Proxima Nova Extrabold, sans-serif;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    font-size: 3.75rem;
  }
`;
export const WelcomeButton = styled.div`
  max-width: 280px;
  margin: 2.5rem auto 0;
`;

export const FilterField = styled.div`
  padding-top: 2rem;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    padding: 2rem 0;
  }
`;

export const FilterTypes = styled.div`
  padding: 1.25rem 1rem;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    padding: 0;
  }

  @media screen and (min-width: ${SCREEN_SIZES.laptop}px) {
    padding: 0;
  }
`;
export const Filter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 1rem;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    width: 30rem;
    padding-right: 0;
    padding-bottom: 0;
  }
  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    width: 34rem;
    padding-right: 0;
    padding-bottom: 0;
  }
`;
export const FilterItem = styled.div`
  width: 48%;
  background-color: var(--sb-light-primary);
  border-radius: 2.5rem;
  border: 3px solid rgb(129, 41, 235);
  height: 2.625rem;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    border-radius: 1rem;
    height: 3.25rem;
  }
`;

export const FeaturedGames = styled.div`
  padding: 2.5rem 1rem 0;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    max-width: 90%;
    margin: 0 auto;
    padding: 2.5rem 1rem 2.5rem;
  }
`;

export const FeaturedList = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 20px;
  }

  @media screen and (min-width: ${SCREEN_SIZES.laptop}px) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
`;

export const CasinoPlay = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(99, 47, 226, 0.9);
  color: white;
`;
export const CasinoPlayIcon = styled.div`
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const CasinoPlayText = styled.div`
  font-size: 1.25rem;
  margin-right: 0.5rem;
`;

export const FeaturedItem = styled.div`
  display: flex;
  width: calc((100% - 1rem) / 2);
  margin-bottom: 0.75rem;
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    padding-top: 70%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:not(:nth-child(2n)) {
    margin-right: 1rem;
  }

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    width: 100%;
    margin-bottom: 0;

    &:not(:nth-child(2n)) {
      margin-right: 0;
    }

    &:nth-child(1) {
      grid-area: 1/1/3/3;
    }

    &:hover {
      ${CasinoPlay} {
        display: flex;
      }
    }
  }
`;

export const Section = styled.div`
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-image: url(${bottomBackgroundImage});
    opacity: 0.15;
  }
`;
