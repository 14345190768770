import React from 'react';
import { Controller } from 'react-hook-form';

import { ArrowDownIcon } from '../../../assets/images/icons';

import * as S from './PaymentSelect.styles';

const PaymentSelect = ({ options, formData, onChange, value, label }) => {
  const renderSelect = props => (
    <S.SelectWrapper>
      <S.Select
        {...props}
        onChange={e => {
          props.onChange(e);
          onChange && onChange(e);
        }}>
        {options.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </S.Select>
      <ArrowDownIcon />
    </S.SelectWrapper>
  );

  return (
    <S.Wrapper>
      {label && <S.Label>{label}</S.Label>}

      {formData ? (
        <Controller
          name={formData.name}
          control={formData.control}
          render={props => {
            const { value, ref } = props.field;

            return renderSelect({ onChange: props.field.onChange, value, ref });
          }}
        />
      ) : (
        renderSelect({ onChange, value })
      )}
    </S.Wrapper>
  );
};

export default PaymentSelect;
