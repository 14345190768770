import React from 'react';

import { NotificationIcon } from '../../assets/images/icons';

import { Icon, Text, Wrapper } from './EmptyView.styled';

const EmptyView = ({
  icon = <NotificationIcon />,
  text,
  color = 'var(--bw-neutral-clr)',
}) => {
  return (
    <Wrapper color={color}>
      <Icon>{icon} </Icon>
      <Text>{text}</Text>
    </Wrapper>
  );
};

export default EmptyView;
