import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { SCREEN_SIZES } from '../../../../providers/DetectDeviceProvider';

export const Wrapper = styled.footer`
  padding: 25px 14px 160px;
  background-color: var(--sb-background-footer);

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    padding: 1.5rem 4.5rem;
  }
`;

export const Top = styled.div`
  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
export const NavItem = styled.div`
  margin-bottom: 20px;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    margin-bottom: 0;
  }
`;
export const NavLink = styled(Link)`
  position: relative;
  font-size: 0.75rem;
  line-height: 1;
  padding-right: 1rem;
  white-space: nowrap;
  color: var(--sb-light-primary);
  text-decoration: none;
  font-weight: 700;
`;

export const Support = styled.div`
  width: 9.4375rem;
  margin: 0 auto;
  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    margin: 0;
  }
`;

export const Info = styled.div`
  margin-top: 1.5rem;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: ${SCREEN_SIZES.laptop}px) {
    justify-content: flex-start;
  }
`;
export const InfoIcon = styled.div`
  display: flex;
  justify-content: center;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  img {
    width: 2.5rem;
  }

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    margin-right: 1rem;
    &:not(:first-child) {
      margin-top: 0;
    }
  }
`;
export const InfoText = styled.div`
  font-size: 0.625rem;
  color: var(--sb-light-secondary);
  text-align: center;
  margin-top: 1.256rem;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    margin-top: 0;
  }
`;

export const Partners = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1.25rem;
`;
export const Partner = styled.div`
  width: 5.25rem;
  margin: 0 0.375rem 0.625rem;
  img {
    width: 100%;
    height: 40px;
  }
`;

export const AllRights = styled.div`
  margin-top: 1.25rem;
  text-align: center;
  font-size: 0.75rem;
  color: var(--sb-light-secondary);

  a {
    color: var(--sb-light-secondary);
  }
`;
