import styled, { css } from 'styled-components';

export const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2.5rem;
  padding: 0 0.75rem;
  font-weight: 700;
  z-index: 3;
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: 2.5rem;
  color: var(--sb-light-primary);
  cursor: pointer;
  width: 100%;
  font-size: 0.75rem;
  transition: 0.2s;

  ${({ fill }) =>
    fill &&
    css`
      background-color: ${fill};
      border-color: ${fill};
      &:hover {
        box-shadow: 0 0 6px ${fill};
      }
    `}

  ${({ stroke }) =>
    stroke &&
    css`
      border-color: ${stroke};

      &:hover {
        box-shadow: 0 0 6px ${stroke};
      }
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${({ big }) =>
    big &&
    css`
      padding: 0.5rem 0.75rem;
      font-size: 1rem;
      height: 3.25rem;
    `}
  
  ${props =>
    props.disabled &&
    css`
      opacity: 0.8;
    `}
`;
export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  img,
  svg {
    width: 100%;
  }
`;
export const Text = styled.div`
  margin-left: 0.75rem;

  &:only-child {
    margin: 0;
  }
`;
