import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import axiosInstance from '../../services/interceptor';
import { features } from '../../whiteLabelConfigs/whitelabelConfigs';
import Button from '../ui/Button/Button';
import Spinner from '../ui/Spinner';

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

import styled from 'styled-components';

import AuthModal from './AuthModal/AuthModal';
import { FormButton, Subtitle } from './Auth.styles';

const EmailVerification = ({ onClose, token }) => {
  const [tokenVerified, setTokenVerified] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    axiosInstance
      .post(`${features.auth.requestVersion}/email-verification`, {
        token,
      })
      .then(() => setTokenVerified('success'))
      .catch(() => setTokenVerified('failed'));
  }, []);

  return (
    <AuthModal title="Confirm Email" onClose={onClose}>
      {tokenVerified === 'success' ? (
        <Subtitle style={{ color: '#00b700' }}>
          {t('registrationSuccessful')}
        </Subtitle>
      ) : tokenVerified === 'failed' ? (
        <Subtitle style={{ color: 'red' }}>{t('registrationFailed')}</Subtitle>
      ) : (
        <SpinnerWrapper>
          <Spinner color="var(--sb-active)" />
        </SpinnerWrapper>
      )}
      <FormButton>
        <Button
          type={'button'}
          text={'OK'}
          fill={'var(--sb-active)'}
          color={'var(--sb-active-contrast)'}
          big
          onClick={onClose}
        />
      </FormButton>
    </AuthModal>
  );
};

export default EmailVerification;
