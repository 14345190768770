import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import UseQueryParams from '../hooks/useQueryParams';

function ScrollToTop({ element }) {
  const { pathname } = useLocation();
  const { searchParams } = UseQueryParams();

  useLayoutEffect(() => {
    (element || document.documentElement).scrollTo({
      top: 0,
      left: 0,
      behavior: element ? 'auto' : 'smooth',
    });
  }, [pathname, searchParams.lIds, searchParams.selectedProvider, element]);

  return null;
}

export default ScrollToTop;
