import React from 'react';

import useQueryParams from '../../hooks/useQueryParams';
import Button from '../ui/Button/Button';

import { FormButton, Subtitle } from './Auth.styles';

const AuthSuccessfully = ({ text }) => {
  const { deleteParam } = useQueryParams();

  return (
    <div style={{ textAlign: 'center' }}>
      <Subtitle>Check Your Email Please</Subtitle>
      <Subtitle>{text}</Subtitle>
      <FormButton>
        <Button
          type={'button'}
          text={'OK'}
          fill={'var(--sb-active)'}
          color={'var(--sb-active-contrast)'}
          big
          onClick={() => deleteParam('popup')}
        />
      </FormButton>
    </div>
  );
};

export default AuthSuccessfully;
