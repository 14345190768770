import React from 'react';
import { useTranslation } from 'react-i18next';

import CurasaoIcon from '../../../../assets/images/icons/curasao.svg';
import EighteenIcon from '../../../../assets/images/icons/eighteen.png';
import SupportIcon from '../../../../assets/images/icons/support.svg';
import { PARTNERS } from '../../../../assets/images/partners';
import Button from '../../../../components/ui/Button/Button';
import { features } from '../../../../whiteLabelConfigs/whitelabelConfigs';

import {
  AllRights,
  Info,
  InfoIcon,
  InfoText,
  Nav,
  NavItem,
  NavLink,
  Partner,
  Partners,
  Support,
  Top,
  Wrapper,
} from './Footer.styled';

const NAV = [
  { title: 'Terms and Conditions' },
  { title: 'FAQ' },
  { title: 'Privacy Policy' },
  { title: 'Responsible Gaming' },
  { title: 'About us' },
  { title: 'Contact us' },
  { title: 'Bonus Terms' },
  { title: 'AML' },
];

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Top>
        <Nav>
          {NAV.map(item => (
            <NavItem key={item.title}>
              <NavLink to={'/'}>{item.title}</NavLink>
            </NavItem>
          ))}
        </Nav>
        <Support>
          <Button
            text={'Online Support'}
            image={SupportIcon}
            fill={'var(--sb-light-secondary)'}
            color={'var(--sb-active-contrast)'}
          />
        </Support>
      </Top>
      <Info>
        <InfoIcon>
          <img src={EighteenIcon} alt="" />
        </InfoIcon>
        <InfoIcon>
          <img src={CurasaoIcon} alt="" />
        </InfoIcon>
        <InfoText>
          {features.title} is owned and operated under Master License #365/JAZ
          granted by the Curaçao
        </InfoText>
      </Info>
      <Partners>
        {PARTNERS.map((item, index) => (
          <Partner key={index}>
            <img src={item.image} alt="" />
          </Partner>
        ))}
      </Partners>
      <AllRights>
        2021 © {process.env.REACT_APP_URL} All rights reserved
      </AllRights>
      {features.poweredBy && (
        <AllRights>
          {t('poweredBy')}{' '}
          <a
            href={`https://${features.poweredBy}`}
            target="_blank"
            rel="noreferrer">
            {features.poweredBy}
          </a>
        </AllRights>
      )}
    </Wrapper>
  );
};

export default Footer;
