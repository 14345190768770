import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';

import { fetchUser } from '../../../../../store/auth/auth.slice';

import axiosInstance from '../../../../../services/interceptor';
import { features } from '../../../../../whiteLabelConfigs/whitelabelConfigs';
import ConfirmPopup from '../../../../ui/Popups/ConfirmPopup/ConfirmPopup';
import MessagePopup from '../../../../ui/Popups/MessagePopup';
import SmallLoader from '../../../../ui/SmallLoader/SmallLoader';
import PaymentSelect from '../../../PaymentSelect/PaymentSelect';

import * as S from './History.styles';
import { CancelButton } from './History.styles';

const statuses = {
  0: 'Pending',
  1: 'Approved',
  2: 'Done',
  3: 'Canceled',
  4: 'Canceled',
};

const History = ({ type }) => {
  const [selected, setSelected] = useState('Deposit');
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  const [canceledId, setCanceledId] = useState(null);
  const [popupMessage, setPopupMessage] = useState(null);
  const [cancelLoading, setCancelLoading] = useState(false);
  const dispatch = useDispatch();

  const options = {
    Deposit: features.deposit.list.map(({ name }) => t(name)),
    Withdraw: features.withdraw.list.map(({ name }) => t(name)),
  };

  const [selectedMethod, setSelectedMethod] = useState(options[selected][0]);

  const endpoint =
    selectedMethod === 'Manual'
      ? 'withdrawal-requests'
      : `crypto/${selected === 'Withdraw' ? 'withdrawals' : 'deposits'}`;

  function getHistory() {
    axiosInstance
      .get(endpoint, {
        params: {
          // transactionType: selected.toLowerCase(),
          status: null,
          limit: 30,
          // ...filter,
          // limit,
          // offset: (currentPage - 1) * limit,
        },
      })
      .then(setData);
  }

  const dataArray = data?.[selected.toLowerCase() + 's'] || data?.data || [];

  useEffect(() => {
    getHistory();
  }, [selected, selectedMethod]);

  const handleConfirm = () => {
    if (cancelLoading) {
      return;
    }

    setCancelLoading(true);

    axiosInstance
      .post('withdrawal-request/cancel', { id: canceledId })
      .then(() => {
        dispatch(fetchUser());
        getHistory();
        setCanceledId(null);
        setPopupMessage({
          type: 'success',
          message: 'Your request was successful!',
        });
      })
      .catch(e =>
        setPopupMessage({ type: 'error', message: capitalize(e.message) }),
      )
      .finally(() => setCancelLoading(false));
  };

  return (
    <S.Wrapper>
      <S.Filters>
        <S.SelectWrapper>
          <PaymentSelect
            onChange={e => setSelected(e.target.value)}
            options={['Deposit', 'Withdraw']}
            value={selected}
          />
        </S.SelectWrapper>

        <S.SelectWrapper>
          <PaymentSelect
            onChange={e => setSelectedMethod(e.target.value)}
            options={options[selected]}
            value={selectedMethod}
          />
        </S.SelectWrapper>
      </S.Filters>

      <S.Table>
        <S.Row>
          <div>Amount</div>
          <div>Method</div>
          <div>Date</div>
          <div>Status</div>
          <div>Action</div>
        </S.Row>
        <S.List>
          {dataArray?.map(item => (
            <S.Item key={item.id}>
              <div>{item.amount}</div>
              <div>{selectedMethod}</div>
              <div>{dayjs.unix(item.createdAt).format('DD/MM HH:mm:ss')}</div>
              <div>{statuses[item.status]}</div>
              <div>
                {item.status === 0 && (
                  <CancelButton onClick={() => setCanceledId(item.id)}>
                    {t('cancel')}
                  </CancelButton>
                )}
              </div>
            </S.Item>
          ))}
        </S.List>
      </S.Table>

      {canceledId && (
        <ConfirmPopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={() => setCanceledId(null)}
          loading={cancelLoading}
        />
      )}
      {popupMessage && (
        <MessagePopup
          type={popupMessage.type}
          message={popupMessage.message}
          onClose={() => setPopupMessage(false)}
        />
      )}
    </S.Wrapper>
  );
};

export default History;
