import React, { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

import Auth from '../../components/Auth/Auth';
import Payments from '../../components/Payments/Payments';
import useQueryParams from '../../hooks/useQueryParams';
import {
  DeviceContext,
  SCREEN_SIZES,
} from '../../providers/DetectDeviceProvider';

import BottomMenu from './components/BottomMenu/BottomMenu';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import MobileMenu from './components/MobileMenu/MobileMenu';
import { OutletField, Wrapper } from './Layout.styled';

const Layout = () => {
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const device = useContext(DeviceContext);
  const isMobile = SCREEN_SIZES[device] < SCREEN_SIZES.tablet;
  const { searchParams } = useQueryParams();
  const { popup } = searchParams;
  const isSportsbookPage = location.pathname.includes('sportsbook');

  return (
    <Wrapper>
      <Header toggleMenu={setIsMobileMenu} />
      <OutletField isSportsbookPage={isSportsbookPage}>
        <Outlet />
      </OutletField>
      {isSportsbookPage ? null : <Footer />}
      {isMobile && (
        <BottomMenu toggleMenu={setIsMobileMenu} isMenu={isMobileMenu} />
      )}
      {isMobileMenu && (
        <MobileMenu active={isMobileMenu} toggleMenu={setIsMobileMenu} />
      )}
      <Auth />
      {popup === 'payment' && <Payments />}
    </Wrapper>
  );
};

export default Layout;
