import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import capitalize from 'lodash/capitalize';

import { fetchUser } from '../../../../../../../store/auth/auth.slice';

import axiosInstance from '../../../../../../../services/interceptor';
import Button from '../../../../../../ui/Button/Button';
import Input from '../../../../../../ui/Input/Input';
import MessagePopup from '../../../../../../ui/Popups/MessagePopup';
import * as S from '../../../../Tabs.styled';

const ManualWithdraw = ({ min, max }) => {
  const [loading, setLoading] = useState(false);
  const [popupMessage, setPopupMessage] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = data => {
    if (loading) {
      return;
    }

    setLoading(true);

    const { amount } = data;

    axiosInstance
      .post('withdrawal-request/create', {
        amount: +amount,
      })
      .then(() => {
        setPopupMessage({
          type: 'success',
          message: 'Your request was successful!',
        });

        dispatch(fetchUser());

        reset();
      })
      .catch(e =>
        setPopupMessage({ type: 'error', message: capitalize(e.message) }),
      )

      .finally(() => setLoading(false));
  };

  return (
    <S.Inner>
      <S.Title>Enter withdraw amount</S.Title>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputWrapper>
          <Input
            type="number"
            error={errors.amount}
            label={t('amount')}
            placeholder="Input amount"
            register={register('amount', { required: true, min, max })}
          />
        </S.InputWrapper>
        <S.Button>
          <Button
            text={t('withdraw')}
            fill={'#3db860'}
            color={'var(--sb-light-primary)'}
            big
            type="submit"
            loading={loading}
          />
        </S.Button>
      </S.Form>

      {popupMessage && (
        <MessagePopup
          type={popupMessage.type}
          message={popupMessage.message}
          onClose={() => setPopupMessage(false)}
        />
      )}
    </S.Inner>
  );
};

export default ManualWithdraw;
