import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { authSelectors } from './store/auth/auth.selectors';

import UseQueryParams from './hooks/useQueryParams';

const AlternarSportsbook = () => {
  const ref = useRef();
  const accessToken = useSelector(authSelectors.selectAccessToken);
  const { setSearchParams } = UseQueryParams();
  const navigate = useNavigate();
  const { lang } = useParams();

  const sporstbookEndpoint = location.pathname.split('sportsbook')[1];
  const [sportsbookUrl, setSporstbookUrl] = useState(sporstbookEndpoint);

  useEffect(() => {
    const listener = e => {
      if (e.data.signIn) {
        setSearchParams({ popup: 'sign-in' });
      } else if (e.data.route && location.pathname.includes('sportsbook')) {
        navigate('/' + lang + '/sportsbook' + e.data.route, { replace: true });
      }
    };

    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);
  }, [lang]);

  useEffect(() => {
    // Set accessToken to sportsbook iframe
    sendMessage('accessToken', accessToken);
  }, [accessToken]);

  function sendMessage(key, value) {
    if (!ref.current) {
      return;
    }

    ref.current.contentWindow.postMessage({ [key]: value }, '*');
  }

  useEffect(() => {
    if (sporstbookEndpoint === '' || sporstbookEndpoint === '/live') {
      setSporstbookUrl(sporstbookEndpoint);
    }
  }, [sporstbookEndpoint]);

  return (
    <iframe
      onLoad={() => {
        sendMessage('accessToken', accessToken);
      }}
      ref={ref}
      src={`${
        process.env.REACT_APP_SPORTSBOOK_DOMAIN || 'https://sportsbook.wasd.vip'
      }${sportsbookUrl}?serverUrl=${
        process.env.REACT_APP_SERVER_URL
      }&lang=${lang}`}
      style={{ height: '100%', width: '100%', border: 0, flexGrow: 1 }}
    />
  );
};

export default AlternarSportsbook;
