import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { authSelectors } from '../../store/auth/auth.selectors';

import { PlayIcon } from '../../assets/images/icons';
import LazyImage from '../../components/LazyImage/LazyImage';
import ThreeStep from '../../components/ThreeStep.styled.js/ThreeStep';
import Button from '../../components/ui/Button/Button';
import useQueryParams from '../../hooks/useQueryParams';
import {
  DeviceContext,
  SCREEN_SIZES,
} from '../../providers/DetectDeviceProvider';
import axiosInstance from '../../services/interceptor';
import { features } from '../../whiteLabelConfigs/whitelabelConfigs';
import Providers from '../Casino/components/Providers/Providers';
import Search from '../Casino/components/Search/Search';
import Types from '../Casino/components/Types/Types';
import { CASINO_HELPER } from '../Casino/utils/helper';

import Banners from './Banners/Banners';
import {
  CasinoPlay,
  CasinoPlayIcon,
  CasinoPlayText,
  FeaturedGames,
  FeaturedItem,
  FeaturedList,
  Filter,
  FilterField,
  FilterItem,
  FilterTypes,
  Section,
  Welcome,
  WelcomeButton,
  WelcomeText,
  WelcomeTitle,
  Wrapper,
  WrapperInner,
} from './Home.styled';

const Home = () => {
  const [orderadGames, setOrderedGames] = useState([]);
  const device = useContext(DeviceContext);
  const isMobile = SCREEN_SIZES[device] < SCREEN_SIZES.tablet;
  const { setSearchParams } = useQueryParams();
  const { lang } = useParams();
  const isLoggedIn = useSelector(authSelectors.selectIsLoggedIn);
  const navigate = useNavigate();

  const onPlayClick = (uuid, section, casinoImage) => {
    CASINO_HELPER.onPlayGame({
      uuid,
      isLoggedIn,
      isMobile,
      section,
      lang,
      navigate,
      setSearchParams,
      backUrl: location.pathname + location.search,
      casinoImage,
    });
  };

  const fetchOrderedGames = () => {
    axiosInstance.get('casino/ordered-games').then(data => {
      setOrderedGames(data);
    });
  };

  useEffect(() => {
    fetchOrderedGames();
  }, []);

  return (
    <Wrapper>
      <WrapperInner>
        {/*<Welcome>*/}
        {/*  <WelcomeText>Ukentlig sport Cashback</WelcomeText>*/}
        {/*  <WelcomeTitle>100% opptil 1000kr</WelcomeTitle>*/}
        {/*  <WelcomeButton>*/}
        {/*    <Button*/}
        {/*      text={'Opprett konto '}*/}
        {/*      fill={'var(--sb-active)'}*/}
        {/*      color={'var(--sb-active-contrast)'}*/}
        {/*      big*/}
        {/*    />*/}
        {/*  </WelcomeButton>*/}
        {/*</Welcome>*/}
        <Banners />
        <Section>
          <FilterField>
            <FilterTypes>
              <Types section={'casino'} />
            </FilterTypes>
            <Filter>
              <FilterItem>
                <Search section={'casino'} />
              </FilterItem>
              <FilterItem>
                <Providers section={'casino'} />
              </FilterItem>
            </Filter>
          </FilterField>
          <FeaturedGames>
            <FeaturedList>
              {orderadGames?.map(game => (
                <FeaturedItem
                  key={game.uui}
                  onClick={() =>
                    onPlayClick(
                      game.uuid,
                      game.section,
                      game.svg || game.image || features.casinoPlaceholder,
                    )
                  }>
                  <div className="img-container">
                    <LazyImage
                      image={
                        game.svg || game.image || features.casinoPlaceholder
                      }
                      alt={game.title}
                      errorView={
                        <img src={features.casinoPlaceholder} alt="" />
                      }
                      loadView={<img src={features.casinoPlaceholder} alt="" />}
                      objectFit={'cover'}
                    />
                  </div>
                  <CasinoPlay>
                    <CasinoPlayText>Play</CasinoPlayText>
                    <CasinoPlayIcon>
                      <PlayIcon />
                    </CasinoPlayIcon>
                  </CasinoPlay>
                </FeaturedItem>
              ))}
            </FeaturedList>
          </FeaturedGames>
          {/*<ThreeStep />*/}
        </Section>
      </WrapperInner>
    </Wrapper>
  );
};

export default Home;
