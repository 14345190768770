import styled, { css } from 'styled-components';

import { SCREEN_SIZES } from '../../../providers/DetectDeviceProvider';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0 1.25rem;

  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    padding: 0 2.5rem;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  color: #6e6d7a;
  font-size: 1rem;
`;

export const Methods = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    width: 275px;
  }
`;
export const MethodsList = styled.div`
  margin-top: 0.75rem;
  background-color: #f7f7f7;
  padding: 0.5rem;
  overflow-y: auto;
`;
export const MethodsItem = styled.div`
  display: flex;
  align-items: center;
  border: 0.5px solid #d3d5de;
  padding: 4px 0 4px 4px;
  border-radius: 0.375rem;
  color: #616577;
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 0.5rem;
  }

  &:hover {
    background-color: rgba(99, 47, 226, 0.7);
    color: var(--sb-light-primary);
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #632fe2;
      color: var(--sb-light-primary);

      &:hover {
        background-color: #632fe2;
      }
    `}
`;
export const MethodsIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  border-radius: 5px;
  width: 100px;
  height: 55px;

  img {
    max-width: 90%;
    max-height: 90%;
  }
`;
export const MethodsInfo = styled.div`
  font-size: 0.75rem;
  margin-left: 0.75rem;
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #6e6d7a;
  margin-top: 2rem;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    width: calc(100% - 275px - 2rem);
    margin-left: 2rem;
    margin-top: 0;
  }
`;

export const HotKeys = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.875rem;
`;
export const HotKey = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #8e92a4;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem;
  cursor: pointer;
  color: #6e6d7a;
  width: 23%;
  padding: 1.25rem 0.25rem;
  margin-right: 0.5rem;
  background-color: transparent;
  text-decoration: none;
  font-size: 0.875rem;
  text-align: center;
  &:hover {
    background-color: rgba(99, 47, 226, 0.7);
    color: var(--sb-light-primary);
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #632fe2;
      color: var(--sb-light-primary);

      &:hover {
        background-color: #632fe2;
      }
    `};

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    font-size: 1rem;
  }
`;

export const InputWrapper = styled.div`
  margin-top: 1.25rem;
`;

export const Button = styled.div`
  width: 100%;
  max-width: 250px;
  margin: 1.25rem auto 0;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 1.25rem;
`;

export const Qr = styled.div`
  overflow-y: auto;
  margin-top: 1rem;
`;

export const QrImage = styled.div`
  width: 120px;
  height: 120px;
  background-color: black;
  margin: 0 auto;
`;

export const QrTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #6e6d7a;
  margin-top: 0.75rem;
`;

export const QrText = styled.div`
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: #6e6d7a;
`;
export const QrAddress = styled(QrText)`
  font-weight: 700;
  margin-top: 0.25rem;
`;

export const QrWarning = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #15191c;
  border: 3px solid red;
  border-radius: 5px;
  padding: 11px;
  margin-bottom: 20px;
`;
