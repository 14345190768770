import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import qs from 'qs';

import axiosInstance from '../services/interceptor';
import { features } from '../whiteLabelConfigs/whitelabelConfigs';

const fields = {
  username: { loading: false, error: null, available: null },
  email: { loading: false, error: null, available: null },
};

const useSignUp = ({ phone, email, username }) => {
  const [customFields, setCustomFields] = useState(fields);

  const checkUsernameAvailable = username => {
    axiosInstance
      .get(`${features.auth.requestVersion}/check-username`, {
        params: { username },
        paramsSerializer: qs.stringify,
      })
      .then(data => {
        let error, available;

        if (data.exist) {
          error = `Username already exist`;
          available = false;
        } else {
          error = null;
          available = true;
        }

        setCustomFields(prev => ({
          ...prev,
          username: {
            error,
            available,
            loading: false,
          },
        }));
      });
  };

  const checkEmailAvailable = email => {
    axiosInstance
      .get(`${auth.requestVersion}/check-email`, {
        params: { email },
        paramsSerializer: qs.stringify,
      })
      .then(data => {
        let error, available;

        if (data.exist) {
          error = `Email already exists`;
          available = false;
        } else {
          error = null;
          available = true;
        }

        setCustomFields(prev => ({
          ...prev,
          email: {
            error,
            available,
            loading: false,
          },
        }));
      });
  };

  const checkPhoneAvailable = phone => {
    axiosInstance
      .get(`${auth.requestVersion}/check-phone`, {
        params: { phone },
        paramsSerializer: qs.stringify,
      })
      .then(data => {
        let error, available;

        if (data.exist) {
          error = `Phone number already exists`;
          available = false;
        } else {
          error = null;
          available = true;
        }

        setCustomFields(prev => ({
          ...prev,
          phone: {
            error,
            available,
            loading: false,
          },
        }));
      });
  };

  const debouncedChangeHandler = useCallback(
    debounce(checkEmailAvailable, 500),
    [],
  );

  const debouncedPhoneChangeHandler = useCallback(
    debounce(checkPhoneAvailable, 500),
    [],
  );

  const debouncedUsernameChangeHandler = useCallback(
    debounce(checkUsernameAvailable, 500),
    [],
  );

  useEffect(() => {
    let loading = false;

    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      loading = true;
      debouncedChangeHandler(email);
    }

    setCustomFields({
      ...customFields,
      email: { loading, error: null, available: false },
    });
  }, [email]);

  useEffect(() => {
    let loading = false;

    if (/^[+0-9]{12}$/.test(phone)) {
      loading = true;
      debouncedPhoneChangeHandler(phone);
    }

    setCustomFields({
      ...customFields,
      phone: { loading, error: null, available: false },
    });
  }, [phone]);

  useEffect(() => {
    let loading = false;

    if (username?.length) {
      debouncedUsernameChangeHandler(username);
      loading = true;
    }

    setCustomFields({
      ...customFields,
      username: { loading, error: null, available: false },
    });
  }, [username]);

  return { customFields, onSetCustomFields: setCustomFields };
};

export default useSignUp;
