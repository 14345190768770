import React from 'react';
import styled from 'styled-components';

import SmallLoader from '../../SmallLoader/SmallLoader';

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Button = styled.button`
  background-color: white;
  border: 1px solid rgb(246, 146, 32);
  color: rgb(246, 146, 32);
  padding: 8px 16px;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;

  :first-child {
    background-color: rgb(246, 146, 32);
    color: white;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const ConfirmPopup = ({ message, onConfirm, onCancel, loading }) => {
  const handleConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <PopupContainer>
      <PopupContent>
        <p>{message}</p>
        <ButtonContainer>
          <Button onClick={handleConfirm}>
            {loading ? <SmallLoader /> : 'Confirm'}
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </ButtonContainer>
      </PopupContent>
    </PopupContainer>
  );
};

export default ConfirmPopup;
