import styled from 'styled-components';

import { SCREEN_SIZES } from '../../../../providers/DetectDeviceProvider';

export const Wrapper = styled.div``;

export const Filter = styled.div`
  max-width: 1092px;
  margin: 2rem auto;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    display: flex;
    justify-content: space-between;
  }
`;
export const FilterItem = styled.div`
  background-color: var(--sb-light-primary);
  border-radius: 0.5rem;
  border: 3px solid rgb(129, 41, 235);
  height: 2.625rem;
  &:not(:first-child) {
    margin-top: 2rem;
  }
  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    height: 3.25rem;
    width: 48%;

    display: flex;
    justify-content: space-between;
    &:not(:first-child) {
      margin-top: 0;
    }
  }
`;

export const CasinoList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1550px;
  margin: 0 auto;
`;

export const CasinoPlay = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(99, 47, 226, 0.9);
  color: var(--sb-light-primary);
`;
export const CasinoPlayIcon = styled.div`
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const CasinoPlayText = styled.div`
  font-size: 1.25rem;
  margin-right: 0.5rem;
`;

export const CasinoItem = styled.div`
  width: calc((100% - 1.5rem) / 2);
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
  cursor: pointer;

  &:not(:nth-child(2n)) {
    margin-right: 1.5rem;
  }

  &::after {
    content: '';
    display: block;
    padding-top: 70%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    width: calc((100% - 3rem) / 3);
    &:nth-child(n) {
      margin-right: 0;
    }
    &:not(:nth-child(3n)) {
      margin-right: 1.5rem;
    }

    &:hover {
      ${CasinoPlay} {
        display: flex;
      }
    }
  }

  @media screen and (min-width: ${SCREEN_SIZES.laptop}px) {
    width: calc((100% - 4.5rem) / 4);
    &:nth-child(n) {
      margin-right: 0;
    }
    &:not(:nth-child(4n)) {
      margin-right: 1.5rem;
    }
  }

  @media screen and (min-width: ${SCREEN_SIZES.desktop}px) {
    width: calc((100% - 7.5rem) / 6);
    &:nth-child(n) {
      margin-right: 0;
    }
    &:not(:nth-child(6n)) {
      margin-right: 1.5rem;
    }
  }
`;

export const ShowMore = styled.div`
  width: 90%;
  max-width: 300px;
  margin: 1rem auto 0;
`;
