import styled, { css } from 'styled-components';

import bannerImage from '../../../assets/images/login/banner-popup.webp';
import { SCREEN_SIZES } from '../../../providers/DetectDeviceProvider';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
`;
export const Inner = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(99, 47, 226, 0.9);
  padding-bottom: 4.72rem;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    width: ${props => (props.withBackgroundImage ? '800px' : '400px')};
    height: auto;
    padding-bottom: 0;
  }
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  color: var(--sb-light-primary);
  max-width: 260px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CloseWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

export const Close = styled.button`
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    fill: var(--sb-light-primary);
  }
`;

export const Info = styled.div`
  width: 100%;
  padding: 2.5rem;
  overflow-y: auto;

  ${props =>
    props.withBackgroundImage &&
    css`
      @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
        width: 50%;
      }
    `}
`;

export const BackgroundImage = styled.div`
  width: 50%;
  background-image: url(${bannerImage});
  background-position: center center;
  background-size: cover;
`;
