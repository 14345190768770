import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getSupportedLanguages } from '../../store/global/global.selector';

import { ArrowDownIcon } from '../../assets/images/icons';
import { LANGUAGES } from '../../providers/I18nextProvider';

import {
  Arrow,
  Body,
  Close,
  Flag,
  Head,
  Item,
  List,
  Wrapper,
} from './LanguageSelection.styled';

const LanguageSelection = ({ toTop }) => {
  const [isOpened, setIsOpened] = useState(false);
  const supportedLanguages = useSelector(getSupportedLanguages);
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();

  const changeLanguageHandler = newLang => {
    i18n.changeLanguage(newLang);
    navigate(`/${newLang}/${location.pathname.replace(`/${lang}/`, '')}`, {
      replace: true,
    });
    localStorage.setItem('lang', newLang);
    setIsOpened(false);
  };

  return (
    <>
      <Wrapper>
        <Head onClick={() => setIsOpened(prevState => !prevState)}>
          <Flag>
            <img src={LANGUAGES[lang].icon} alt={LANGUAGES[lang].title} />
          </Flag>
          <Arrow active={isOpened}>
            <ArrowDownIcon />
          </Arrow>
        </Head>
        {isOpened && (
          <>
            <Close
              onClick={() => {
                setIsOpened(false);
              }}
            />
            <Body toTop={toTop}>
              <List>
                {supportedLanguages.map(item => (
                  <Item key={item} onClick={() => changeLanguageHandler(item)}>
                    <Flag>
                      <img src={LANGUAGES[item].icon} alt="" />
                    </Flag>
                  </Item>
                ))}
              </List>
            </Body>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default LanguageSelection;
