import { useEffect } from 'react';

const useDisableScroll = () => {
  useEffect(() => {
    document.documentElement.classList.add('dontScroll');

    return () => {
      document.documentElement.classList.remove('dontScroll');
    };
  }, []);

  return null;
};

export default useDisableScroll;
