import React, { useState } from 'react';

import { features } from '../../../../../whiteLabelConfigs/whitelabelConfigs';
import * as S from '../../Tabs.styled';
import PaymentList from '../PaymentList';

import CryptoWithdraw from './components/CryptoWithdraw/CryptoWithdraw';
import ManualWithdraw from './components/ManualWithdraw/ManualWithdraw';

const components = {
  crypto: CryptoWithdraw,
  manual: ManualWithdraw,
};

const Withdraw = () => {
  const [selectedWithdraw, setSelectedWithdraw] = useState(
    features.withdraw.list[0],
  );

  const Component = components[selectedWithdraw.name];

  return (
    <S.Wrapper>
      <S.Methods>
        <S.Title>Select payment method</S.Title>
        <PaymentList
          list={features.withdraw.list}
          onSelect={setSelectedWithdraw}
          selectedItem={selectedWithdraw}
        />
      </S.Methods>
      <Component
        min={selectedWithdraw.minAmount}
        max={selectedWithdraw.maxAmount}
      />
    </S.Wrapper>
  );
};

export default Withdraw;
