import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { css, keyframes } from 'styled-components';

import { CloseIcon } from '../../../assets/images/icons';

const slideInRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(100% + 10px));
  }
`;

const PopupContainer = styled.div`
  z-index: 1000;
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: ${props =>
    props.type === 'success' ? '#2ecc71' : '#e74c3c'};
  color: var(--sb-light-primary);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: ${slideInRight} 0.3s;

  ${props =>
    props.hide &&
    css`
      animation: ${slideOut} 0.3s;
      animation-fill-mode: forwards;
    `}
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: var(--sb-light-primary);
  font-size: 1.2rem;
  cursor: pointer;
  margin-left: 20px;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--sb-light-primary);
  }
`;

const PopupMessage = styled.p`
  margin: 0;
`;

const MessagePopup = ({ type, message, onClose }) => {
  const [visible] = useState(true);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleClose();
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  const handleClose = () => {
    setHide(true);

    setTimeout(() => {
      onClose();
    }, 500);
  };

  return createPortal(
    <>
      {visible && (
        <PopupContainer type={type} hide={hide}>
          <PopupMessage>{message}</PopupMessage>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </PopupContainer>
      )}
    </>,
    document.body,
  );
};

export default MessagePopup;
