import React from 'react';

import SmallLoader from '../SmallLoader/SmallLoader';

import { Icon, Text, Wrapper } from './Button.styled';

const Button = ({
  type = 'button',
  image,
  text,
  fill,
  color,
  big,
  onClick,
  stroke,
  loading,
  disabled,
}) => {
  return (
    <Wrapper
      disabled={disabled || loading}
      fill={fill}
      color={color}
      big={big}
      stroke={stroke}
      type={type}
      onClick={onClick}>
      {image && (
        <Icon>
          <img src={image} alt="" />
        </Icon>
      )}
      {loading ? <SmallLoader /> : text ? <Text>{text}</Text> : null}
    </Wrapper>
  );
};

export default Button;
