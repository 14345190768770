import { useSearchParams } from 'react-router-dom';
import qs from 'qs';

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const paramsObject = qs.parse(searchParams.toString());

  function handleChangeParams(params, keepValues = true) {
    if (keepValues) {
      setSearchParams({ ...paramsObject, ...params }, { replace: true });
      return;
    }
    setSearchParams(params, { replace: true });
  }

  function deleteKey(key) {
    searchParams.delete(key);
    setSearchParams(searchParams, { replace: true });
  }

  const params = {
    ...paramsObject,
  };

  return {
    setSearchParams: handleChangeParams,
    searchParams: params,
    deleteParam: deleteKey,
  };
};

export default useQueryParams;
