import styled from 'styled-components';

export const Subtitle = styled.div`
  color: var(--sb-light-primary);
  font-size: 1rem;
  display: block;
  margin-top: 1rem;
`;

export const Form = styled.form`
  margin-top: 1.25rem;
`;

export const FormItem = styled.div`
  color: white;

  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const FormButton = styled.div`
  margin-top: 1.25rem;

  span {
    text-align: center;
    color: red;
    display: block;
    margin-top: 4px;
  }
`;
