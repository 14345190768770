import { API } from '../../api';
import axiosInstance from '../../services/interceptor';

export async function getConfigs() {
  return axiosInstance.get('/configs');
}

export async function getMainBanner() {
  return axiosInstance.get(API.ui.getMainBanner);
}
