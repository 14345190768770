import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { SCREEN_SIZES } from '../../../../providers/DetectDeviceProvider';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  margin: 0 auto;
  user-select: none;
  background: #0e0c25cc;
  border-radius: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--sb-light-primary);
  }

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    width: 4rem;
    height: 4rem;
  }
`;

export const Item = styled(NavLink)`
  cursor: pointer;
  color: var(--sb-light-primary);
  padding: 0 1rem;
  text-decoration: none;
  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  &:hover {
    color: var(--sb-active-hover);

    ${Icon} {
      background: var(--sb-active-hover);

      svg {
        fill: black;
      }
    }
  }

  &.active {
    color: var(--sb-active);
    ${Icon} {
      background: var(--sb-active);

      svg {
        fill: black;
      }
    }
  }
`;

export const Name = styled.div`
  margin-top: 0.5rem;
  font-size: 0.625rem;
  text-align: center;

  @media screen and (min-width: ${SCREEN_SIZES.tablet}px) {
    font-size: 0.875rem;
  }
`;
