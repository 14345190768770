import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { debounce } from 'lodash';

import closeImage from '../../../../assets/images/icons/close.svg';
import searchImage from '../../../../assets/images/icons/search.svg';
import useQueryParams from '../../../../hooks/useQueryParams';

import { SearchIcon, SearchInput, SearchLabel } from './Search.styled';

const ROUTE_NAMES = {
  casino: 'slot',
  'live-casino': 'live',
  'virtual-sport': 'virtual',
};

const Search = ({ section }) => {
  const { searchParams, deleteParam } = useQueryParams();
  const { search } = searchParams;
  const [value, setValue] = useState(search);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { lang } = useParams();
  const handleChangeSearch = useCallback(
    debounce(
      value =>
        navigate(`/${lang}/casino/${ROUTE_NAMES[section]}?search=${value}`, {
          replace: true,
        }),
      400,
    ),
    [section, lang],
  );

  useEffect(() => {
    setValue(search || '');
  }, [pathname]);

  useEffect(() => {
    value ? handleChangeSearch(value) : deleteParam('search');
  }, [value]);

  return (
    <SearchLabel>
      <SearchIcon>
        <img src={searchImage} alt="" />
      </SearchIcon>
      <SearchInput
        value={value}
        onChange={event => setValue(event.target.value)}
        placeholder={'Search'}
      />
      {(search || value) && (
        <SearchIcon
          onClick={() => {
            setValue('');
            deleteParam('search');
          }}>
          <img src={closeImage} alt="" />
        </SearchIcon>
      )}
    </SearchLabel>
  );
};

export default Search;
