import styled from 'styled-components';

export const Forgot = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: var(--sb-active);
  border: none;
  background: rgba(0, 0, 0, 0);
  transition: 0.3s ease-in-out;
  text-align: center;
  margin-top: 1rem;
  text-decoration: underline;
`;

export const Loader = styled.div`
  min-height: 343px;
  height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
