import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  height: calc(100vh - 3.25rem);
  position: relative;
  z-index: 1;
  padding: 2rem 9rem 2rem;
  ${({ image }) =>
    image &&
    css`
      background-image: url(${image});
      background-size: 100% 100%;

      &::after {
        backdrop-filter: blur(13px);
        background-color: var(--777-black-5);
        bottom: 0;
        content: '';
        inset-inline-start: 0;
        position: absolute;
        inset-inline-end: 0;
        top: 0;
        z-index: -1;
      }
    `}
`;

export const Inner = styled.div`
  background-color: var(--sb-light-primary);
  height: 100%;
  position: relative;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--sb-light-primary);
  position: absolute;
  z-index: 10;
  inset-inline-end: 0;
  top: 0;
  transform: translateX(100%);

  ${props =>
    props.theme.dir === 'rtl' &&
    css`
      transform: translateX(-100%);
    `}
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-align: center;
  padding: 0;
  width: 3.125rem;
  height: 2.81rem;
  border-top-right-radius: 0.56rem;
  border-bottom-right-radius: 0.56rem;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;

  svg {
    transition: 0.3s;
    width: 1.2rem;
    height: 1.2rem;
    fill: var(--sb-light-primary);
  }

  &:hover {
    svg {
      transform: rotate(-90deg);
    }
  }
  ${props =>
    props.theme.dir === 'rtl' &&
    css`
      border-radius: 0;
      border-top-left-radius: 0.56rem;
      border-bottom-left-radius: 0.56rem;
    `}
`;
