import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { API } from '../../api';
import useQueryParams from '../../hooks/useQueryParams';
import axiosInstance from '../../services/interceptor';
import Button from '../ui/Button/Button';
import Input from '../ui/Input/Input';

import AuthModal from './AuthModal/AuthModal';
import { Form, FormButton, FormItem, Subtitle } from './Auth.styles';
import AuthSuccessfully from './AuthSuccessfully';

const ForgotPassword = () => {
  const { deleteParam } = useQueryParams();

  const [requestData, setRequestData] = useState({
    success: false,
    error: null,
    loading: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = data => {
    setRequestData({ ...requestData, loading: true });
    axiosInstance
      .post(API.auth.forgotPassword, data)
      .then(() =>
        setRequestData({ ...requestData, success: true, loading: false }),
      )
      .catch(error =>
        setRequestData({
          ...requestData,
          error: error.message,
          loading: false,
        }),
      );
  };

  return (
    <AuthModal title="Password recovery" onClose={() => deleteParam('popup')}>
      {requestData.success ? (
        <AuthSuccessfully text="We have sent a reset password link to your email address!" />
      ) : (
        <>
          <Subtitle>
            Please enter your Email address or User ID in order to start the
            process of retrieving password.
          </Subtitle>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormItem>
              <Input
                title={'Email'}
                placeholder={'Enter your email'}
                error={'email' in errors}
                register={register('email', {
                  required: { value: true, message: 'Email is required' },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Wrong email',
                  },
                })}
              />
            </FormItem>
            <FormButton>
              <Button
                type="submit"
                text={'Send Now'}
                fill={'var(--sb-active)'}
                color={'var(--sb-active-contrast)'}
                big
                loading={requestData.loading}
              />
              {requestData.error && <span>{requestData.error}</span>}
            </FormButton>
          </Form>
        </>
      )}
    </AuthModal>
  );
};

export default ForgotPassword;
