import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import store, { persistor } from './store/store';

import ScrollToTop from './components/ScrollToTop';
import SuspenseComponent from './components/SuspenseComponent/SuspenseComponent';
import AuthProvider from './providers/AuthProvider';
import CheckAuthRoutes from './providers/CheckAuthRoutes';
import DetectDeviceProvider from './providers/DetectDeviceProvider';
import MyI18nextProvider from './providers/I18nextProvider';
import InitialConfigProvider from './providers/InitialConfigProvider';
import MyThemeProvider from './providers/ThemeProvider';
import reportWebVitals from './reportWebVitals';
import Router from './router';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <MyThemeProvider>
    <Suspense fallback={<SuspenseComponent />}>
      <Provider store={store}>
        <PersistGate loading={<SuspenseComponent />} persistor={persistor}>
          <BrowserRouter>
            <AuthProvider>
              <InitialConfigProvider>
                <MyI18nextProvider>
                  <CheckAuthRoutes>
                    <DetectDeviceProvider>
                      <Router />
                      <ScrollToTop />
                    </DetectDeviceProvider>
                  </CheckAuthRoutes>
                </MyI18nextProvider>
              </InitialConfigProvider>
            </AuthProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </Suspense>
  </MyThemeProvider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
