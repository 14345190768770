import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { authActions } from '../../store/auth/auth.slice';

import { API } from '../../api';
import axiosInstance from '../../services/interceptor';
import Button from '../ui/Button/Button';
import Input from '../ui/Input/Input';

import AuthModal from './AuthModal/AuthModal';
import { Form, FormButton, FormItem, Subtitle } from './Auth.styles';
import AuthSuccessfully from './AuthSuccessfully';

const ResetPassword = ({ onClose, token }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at 6 char long'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords does not match'),
  });

  const formOptions = { resolver: yupResolver(formSchema) };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = data => {
    setLoading(true);
    axiosInstance
      .post(API.auth.resetPassword, { password: data.password, token })
      .then(res => setData(res))
      .finally(() => setLoading(false));
  };

  function singIn() {
    dispatch(
      authActions.setToken({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      }),
    );

    onClose();
  }

  return (
    <AuthModal title={'Reset Password'} withBackgroundImage onClose={onClose}>
      {data ? (
        <>
          <Subtitle>{t('passwordSuccessfullyReset')}</Subtitle>
          <Subtitle>{t('clickToLogInMagically')}</Subtitle>

          <FormButton>
            <Button
              type="button"
              onClick={singIn}
              text="Continue"
              fill={'var(--sb-active)'}
              color={'var(--sb-active-contrast)'}
              big></Button>
          </FormButton>
        </>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormItem>
            <Input
              error={errors.password}
              title={'Password'}
              placeholder={'Password'}
              type={'password'}
              register={register('password')}
            />
          </FormItem>
          <FormItem>
            <Input
              error={errors.confirmPassword}
              title={'Password Confirmation'}
              placeholder={'Password Confirmation'}
              type={'password'}
              register={register('confirmPassword')}
            />
          </FormItem>
          <FormButton>
            <Button
              loading={loading}
              type={'submit'}
              text={'Reset'}
              fill={'var(--sb-active)'}
              color={'var(--sb-active-contrast)'}
              big
            />
          </FormButton>
        </Form>
      )}
    </AuthModal>
  );
};

export default ResetPassword;
