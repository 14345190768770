import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';

import axiosInstance from '../../../../../../../services/interceptor';
import Button from '../../../../../../ui/Button/Button';
import Input from '../../../../../../ui/Input/Input';
import MessagePopup from '../../../../../../ui/Popups/MessagePopup';
import PaymentSelect from '../../../../../PaymentSelect/PaymentSelect';
import * as S from '../../../../Tabs.styled';

const options = {
  USDT: ['TRON', 'ETH'],
  TRX: ['TRON'],
  BTC: ['BTC'],
  ETH: ['ETH'],
  LTC: ['LTC'],
  DASH: ['DASH'],
};

const currencies = Object.keys(options);

const CryptoWithdraw = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [popupMessage, setPopupMessage] = useState(null);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      currency: currencies[0],
      network: options[currencies[0]][0],
    },
  });

  const onSubmit = data => {
    setLoading(true);

    const { amount, ...rest } = data;

    axiosInstance
      .post('crypto/withdrawal', {
        fields: { ...rest },
        amount: +amount,
      })
      .then(() => {
        setPopupMessage({
          type: 'success',
          message: 'Your request was successful!',
        });

        reset();
      })
      .catch(e =>
        setPopupMessage({ type: 'error', message: capitalize(e.message) }),
      )

      .finally(() => setLoading(false));
  };

  const [currency] = watch(['currency']);
  const networks = options[currency];

  return (
    <S.Inner>
      <S.Title>Choose or enter withdraw amount</S.Title>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        {/*<S.HotKeys>*/}
        {/*  <S.HotKey type={'button'} active>*/}
        {/*    $ 100*/}
        {/*  </S.HotKey>*/}
        {/*  <S.HotKey type={'button'}>$ 100</S.HotKey>*/}
        {/*  <S.HotKey type={'button'}>$ 100</S.HotKey>*/}
        {/*  <S.HotKey type={'button'}>$ 100</S.HotKey>*/}
        {/*</S.HotKeys>*/}
        <S.InputWrapper>
          <PaymentSelect
            onChange={e => setValue('network', options[e.target.value][0])}
            options={currencies}
            formData={{ control, name: 'currency' }}
            label={t('currency')}
          />
        </S.InputWrapper>

        <S.InputWrapper>
          <PaymentSelect
            options={networks}
            formData={{ control, name: 'network' }}
            label={t('network')}
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input
            error={errors.address}
            label={t('wallet')}
            placeholder="Input your wallet"
            register={register('address', { required: true, minLength: 10 })}
          />
        </S.InputWrapper>
        <S.InputWrapper>
          <Input
            type="number"
            error={errors.amount}
            label={t('amount')}
            placeholder="Input amount"
            register={register('amount', { required: true })}
          />
        </S.InputWrapper>
        {/*<S.Qr>*/}
        {/*  <S.QrImage />*/}
        {/*  <S.QrTitle>Fund your balance with Etherium</S.QrTitle>*/}
        {/*  <S.QrText>popup.crypto.ETH.USD.body</S.QrText>*/}
        {/*  <S.QrAddress>0xbfa2dabc094a10b927be9c17b987dd1b21804ea5</S.QrAddress>*/}
        {/*  <S.QrWarning>*/}
        {/*    Please be careful to send only Etherium to this address, sending any*/}
        {/*    other currency may result in a deposit delay or funds being lost.*/}
        {/*  </S.QrWarning>*/}
        {/*</S.Qr>*/}
        <S.Button>
          <Button
            text={t('withdraw')}
            fill={'#3db860'}
            color={'var(--sb-light-primary)'}
            big
            type="submit"
            loading={loading}
          />
        </S.Button>
      </S.Form>

      {popupMessage && (
        <MessagePopup
          type={popupMessage.type}
          message={popupMessage.message}
          onClose={() => setPopupMessage(false)}
        />
      )}
    </S.Inner>
  );
};

export default CryptoWithdraw;
